import { Component, Vue } from 'vue-property-decorator';
import { TTreeStruct } from '@/dto/base.dto';

@Component
export default class NormalizeTree extends Vue {
  normalizeTree(node): TTreeStruct {
    const tree: TTreeStruct = {
      id: node.id,
      label: 'cardNum' in node ? `${node.name ?? node.cardNum} (${node.currency})` : node.name,
      children: node.children ?? [],
    };
    if (node.children == null || node.children == 'null' || node.children.length == 0) {
      delete tree.children;
      delete node.children;
    }
    return tree;
  }

  searchTree(tree: TTreeStruct[], value: unknown, key = 'id', reverse = false) {
    for (const [, v] of Object.entries(tree)) {
      const stack = [v];
      while (stack.length) {
        const node = stack[reverse ? 'pop' : 'shift']();
        if (!node) continue;
        if (node[key] === value) return node;
        node.children && stack.push(...node.children);
      }
    }
    return null;
  }
}
