
import { Component, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { ProjectService } from '@/services/project.service';
import { ProjectDto } from '@/dto/Projects.dto';
import { TreeDefaultProps } from '@/dto/base.dto';
import { TreeNode } from 'element-ui/types/tree';

@Component
export default class ProjectsView extends Vue {
  @PropSync('projects', { type: Array, default: [] }) filteredProjects!: ProjectDto[];

  defaultProps: TreeDefaultProps = {
    children: 'children',
    label: 'name',
  };
  filterText = '';

  selectedProject: ProjectDto = {} as ProjectDto;

  @Ref('tree') readonly tree!: Element;

  @Watch('filterText')
  onFilterTexthanged(val: string) {
    (this.$refs.tree as Vue & { filter: (val: string) => void }).filter(val);
  }

  filterNode(value: string, data: ProjectDto) {
    if (!value) return true;
    return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }

  async remove(node: TreeNode<number, ProjectDto>, data: ProjectDto) {
    const id = data.id;
    const answer = await ProjectService.delete([id]);

    if (answer.data.errors === null) {
      const children = node.parent?.childNodes;
      if (children) {
        const index = children.findIndex((d) => d.data.id === data.id);
        children.splice(index, 1);
      }
    }
  }

  async handleDrop(draggingNode, dropNode, dropType: string) {
    if (dropType !== 'none') {
      let dest = null;
      if (dropType === 'inner') {
        dest = dropNode.data.id;
      } else if (dropNode.parent.id !== 0) {
        dest = dropNode.parent.data.id;
      }

      const src = draggingNode.data.id;
      await ProjectService.move(src, dest);
    }
  }

  async append() {
    const answer = await ProjectService.insert({ name: this.filterText });
    this.filteredProjects = answer.data;
    this.filterText = '';
  }

  selectProject(data) {
    this.selectedProject = data;
  }
}
