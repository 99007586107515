import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';
import { IBalanceState } from '@/store/interfaces';

export const state: IBalanceState = {
  balance: {},
  balanceOfSelected: null,
};

const namespaced = true;

export const balanceState: Module<IBalanceState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
