import { CompanyDto } from '@/dto/Company.dto';
import axios from 'axios';

import { BaseService } from './base.service';
import { ResponseWrapper, ErrorWrapper } from './util';

export class BalanceService extends BaseService {
  static async getCompanyBalance(): Promise<ResponseWrapper> {
    try {
      const response = await this.request({ auth: true }).get('pays/balance-by-company');

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async recalcBalance(company: CompanyDto) {
    try {
      const response = await this.request({ auth: true }).post(`pays/recalc-balance`, {
        id: company.id,
      });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }
}
