import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { authState } from './modules/auth';
import { articleList } from './modules/article';
import { cardList } from './modules/card';
import { projectList } from './modules/project';
import { balanceState } from './modules/balance';
import { contragentList } from './modules/contragent';
import { sortState } from './modules/sort';
import { localeState } from './modules/locale';
import { navigationState } from './modules/navigation';
import { enumState } from './modules/enum';
import { filterList } from './modules/filter';
import { dashboardState } from './modules/dashboard';
import { userState } from './modules/user';
import { toastState } from './modules/toast';
import { domState } from './modules/dom/index';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
  },
  modules: {
    articleList,
    authState,
    balanceState,
    cardList,
    contragentList,
    dashboardState,
    domState,
    enumState,
    filterList,
    localeState,
    navigationState,
    projectList,
    sortState,
    toastState,
    userState,
  },
};

export default new Vuex.Store<RootState>(store);
