import { ArticleDto } from './Article.dto';
import { CardDto } from './Card.dto';
import { ContragentDto } from './Contragent.dto';
import { PayTypeSource, CurrencyCode, PeriodicList, PayType } from './enums';
import { ProjectDto } from './Projects.dto';

export type PaysTableDto = {
  id: number;
  isApproved: boolean;
  numRow: number;
  bank: string;
  card: { name: string };
  dateTimeOperation: Date;
  dateTimeAccrual: Date;
  sumOplata: number;
  currency: string;
  description: string;
  customDescription: string;
  contragent: { name: string };
  project?: object;
  article?: object;
  divided?: PaysTableDto[];
  parent?: PaysTableDto;
  transferId?: number;
};
export type TPay = {
  id: number;
  isApproved: boolean;
  contragent?: ContragentDto | null;
  project?: ProjectDto | null;
  article?: ArticleDto | null;
  currency: number;
  sumOplata: number;
  bank: string;
  card: CardDto | number | null;
  description?: string;
  courseAmount?: string;
  dateTimePay: Date | string;
  dateTimeOperation: Date | string;
  dateTimeAccrual: Date | string;
  numDocument?: string;
  customDescription?: string;
  typeSource: PayTypeSource;
  payType: PayType;
  divided?: TDividePay[];
  formatNameArticle: TFormatArtProj;
  formatNameProject: TFormatArtProj;
  transferId?: number;
  transfer?: TPay | null;
};

export type TCurrencyBalance = {
  [currency: string]: {
    positive: number;
    negative: number;
  };
};

export type TNewPay = Partial<TPay> & { periodicSettings?: TPeriodicSettings };

export type TPeriodicSettings = {
  period: PeriodicList | null;
  countPeriod: number;
};

export type TDividePay = {
  id?: number;
  sumOplata: number;
  project?: number | null;
  article?: number | null;
  dateTimeAccrual: Date;
  isApproved: boolean;
};

export type TFormatArtProj = {
  parent: boolean;
  names: string[];
};

export const newPay = (row: TNewPay | null = null): TNewPay => {
  return row
    ? ({
        id: row.id,
        isApproved: row.isApproved,
        bank: row.bank,
        card: (row.card as CardDto).id,
        contragent: row.contragent?.id,
        dateTimePay: row.dateTimePay,
        dateTimeOperation: row.dateTimeOperation,
        dateTimeAccrual: row.dateTimeAccrual,
        sumOplata: Math.abs(row.sumOplata ?? 0),
        currency: row.currency,
        customDescription: row.customDescription,
        project: row.project?.id,
        article: row.article?.id,
        typeSource: row.typeSource,
        payType: row.payType,
        divided: row.divided,
        transferId: row.transferId,
      } as TNewPay)
    : ({
        isApproved: false,
        bank: 'UNKNOWN',
        card: null,
        contragent: null,
        dateTimeOperation: new Date(),
        sumOplata: 0,
        currency: CurrencyCode.UAH,
        customDescription: '',
        project: null,
        article: null,
        typeSource: PayTypeSource.MANUAL,
        payType: PayType.PAY_INCOME,
        divided: [],
      } as TNewPay);
};

export type TTransfer = {
  id?: number;
  dateTimeOperation: Date | string;
  sumOplata: number;
  card?: CardDto | number | null;
  project?: ProjectDto | null;
  article?: ArticleDto | null;
  description?: string;
  customDescription?: string;
  isApproved: boolean;
  payType: PayType;
  transferId?: number;
};
