import { MutationTree } from 'vuex';
import { INavigationState } from '../../interfaces';

export const mutations: MutationTree<INavigationState> = {
  SET_MINI_STATE(state, newState) {
    state.mini = newState;
    localStorage.setItem('navigationState', JSON.stringify(state));
  },
  SET_DRAWER_STATE(state, newState) {
    state.drawer = newState;
    localStorage.setItem('navigationState', JSON.stringify(state));
  },
  SET_PANEL_STATE(state, newState) {
    state.panel = newState;
    localStorage.setItem('navigationState', JSON.stringify(state));
  },
  TOGGLE_DRAWER(state) {
    state.drawer = !state.drawer;
    localStorage.setItem('navigationState', JSON.stringify(state));
  },
  SET_ALL_NAVIGATIONS(state, newState) {
    state.drawer = newState.drawer;
    state.mini = newState.mini;
    state.panel = newState.panel;
  },
};
