import { IFilterState } from '@/store/interfaces';
import moment from 'moment-timezone';

const tz = moment.tz.guess();

export const defaultSate: IFilterState = {
  pays: {
    dateRange: {
      from: moment(new Date()).tz(tz).subtract(1, 'month').startOf('day').toISOString(),
      to: moment(new Date()).tz(tz).endOf('day').toISOString(),
    },
    sumFilter: {
      from: 0,
      to: 0,
    },
    bankFilter: [],
    projectFilter: [],
    articleFilter: [],
    contragentFilter: [],
    cardFilter: [],
    descriptionFilter: '',
    isApprovedFilter: null,
    payTypeFilter: null,
  },
  contragent: [],
  project: [],
  card: [],
  accounts: [],
};
