
import { Component, Vue } from 'vue-property-decorator';

import CompanySettings from '@/components/Settings/company/CompanySettings.vue';
import UsersSettings from '@/components/Settings/users/UsersSettings.vue';
import InviteSettings from '@/components/Settings/invite/InviteSettings.vue';
import WebHookSettings from '@/components/Settings/webHook/WebHookSettings.vue';
import Alert from '@/components/Alerts/alert.vue';
import LogTable from '@/components/Settings/logs/LogTable.vue';

@Component({
  components: { CompanySettings, UsersSettings, InviteSettings, WebHookSettings, LogTable },
})
export default class SettingsPage extends Vue {
  alertBox: Alert = {} as Alert;
  currentRouteName = 'company';

  mounted() {
    this.alertBox = this.$refs.alertBox as Alert;
  }
}
