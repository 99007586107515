import * as Fingerprint2 from 'fingerprintjs2';
import * as UAParser from 'ua-parser-js';

import { Http } from './http.init';
import { ResponseWrapper, ErrorWrapper } from './util';
import $store from '../store';
import $router from '../router';

import { BaseService } from './base.service';
import { UsersService } from './users.service';
import { ArticleService } from './article.service';
import { ContragentService } from './contragent.service';
import { ProjectService } from './project.service';
import { CardService } from './card.service';

let BEARER = '';
let fingerprint = '';

export class AuthService extends BaseService {
  /**
   ******************************
   * @API
   ******************************
   */

  static async makeLogin({ email, password }) {
    try {
      if (!fingerprint) fingerprint = await _getFingerprint();
      const response = await this.request().post('/auth/login', {
        email,
        password,
        fingerprint,
      });
      _setAuthData({
        accessToken: response.data.accessToken,
        exp: response.data.expiresAccessIn,
        refreshTokenObj: {
          token: response.data.refreshToken,
          expires: response.data.expiresRefreshIn,
        },
      });
      await _afterLogin();

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async makeLogout() {
    try {
      if (!fingerprint) fingerprint = await _getFingerprint();

      const refreshToken = _getRefreshToken();
      const response = await new Http({ auth: true }).post('auth/logout', {
        fingerprint,
        refreshToken: refreshToken?.token,
      });
      _resetAuthData();
      $router.push({ name: 'login' }).catch((err) => {
        console.log('makeLogout', err);
      });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async refreshTokens() {
    try {
      const refreshToken = _getRefreshToken();
      if (!refreshToken) throw new ErrorWrapper(new Error("Refresh token doesn't exist!"));
      if (AuthService.isRefreshTokenExpired()) throw new ErrorWrapper(new Error('Refresh token expired!'));
      if (!fingerprint) fingerprint = await _getFingerprint();

      const response = await this.request().post('/auth/refresh-tokens', {
        fingerprint,
        refreshToken: refreshToken.token,
      });
      _setAuthData({
        accessToken: response.data.accessToken,
        exp: response.data.expiresAccessIn,
        refreshTokenObj: {
          token: response.data.refreshToken,
          expires: response.data.expiresRefreshIn,
        },
      });

      await _afterLogin();

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      _resetAuthData();
      $router.push({ name: 'login' }).catch((err) => {
        console.log('refreshTokens', err);
      });
      throw new ErrorWrapper(error);
    }
  }

  static async registerFull(form) {
    try {
      const response = await this.request().post('/auth/register-full', {
        ...form,
      });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async validateRegUser(email) {
    try {
      const response = await this.request().post('/auth/validate-user', {
        email,
      });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async validateRegCompany(company) {
    try {
      const response = await this.request().post('/auth/validate-company', {
        company,
      });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static debounceRefreshTokens = this._debounce(() => {
    return this.refreshTokens();
  }, 300);

  /**
   ******************************
   * @METHODS
   ******************************
   */

  static isAccessTokenExpired() {
    const accessTokenExpDate = $store.state.authState.accessTokenExpDate - 10;
    const nowTime = new Date().getTime();

    return accessTokenExpDate <= nowTime;
  }

  static isRefreshTokenExpired() {
    const refreshToken = _getRefreshToken();
    if (!refreshToken) return true;
    const refreshTokenExpDate = refreshToken.expires - 10;
    const nowTime = new Date().getTime();

    return refreshTokenExpDate <= nowTime;
  }

  static hasRefreshToken() {
    return Boolean(localStorage.getItem('refreshToken'));
  }

  static setRefreshToken(refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  static getBearer() {
    return BEARER;
  }

  static setBearer(accessToken) {
    BEARER = `Bearer ${accessToken}`;
  }

  static isLoggedIn() {
    return $store.state.userState.currentUserRole && $store.state.userState.currentCompanyId !== null;
  }

  /**
   * https://stackoverflow.com/questions/35228052/debounce-function-implemented-with-promises
   * @param inner
   * @param ms
   * @returns {function(...[*]): Promise<unknown>}
   * @private
   */
  static _debounce(inner, ms = 0) {
    let timer = null;
    let resolves = [];

    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        const result = inner();
        resolves.forEach((r) => r(result));
        resolves = [];
      }, ms);

      return new Promise((resolve) => resolves.push(resolve));
    };
  }
}

/**
 ******************************
 * @private_methods
 ******************************
 */

function _resetAuthData() {
  // reset userData in store
  $store.commit('userState/SET_CURRENT_USER_ROLE', null);
  $store.commit('userState/SET_CURRENT_COMPANY', null);
  $store.commit('userState/SET_CURRENT_COMPANY_ID', null);
  $store.commit('authState/SET_ATOKEN_EXP_DATE', null);

  $store.commit('enumState/SET_BANKS_LIST', []);
  $store.commit('enumState/SET_CURRENCY_LIST', []);
  $store.commit('enumState/SET_PERIODIC_LIST', []);
  $store.commit('projectList/SET_PROJECT_LIST', []);
  $store.commit('articleList/SET_ARTICLE_LIST', []);
  $store.commit('cardList/SET_CARD_LIST', []);
  $store.commit('contragentList/SET_CONTRAGENT_LIST', []);

  // reset tokens
  AuthService.setRefreshToken('');
  AuthService.setBearer('');
}

function _setAuthData({ accessToken, exp, refreshTokenObj } = {}) {
  AuthService.setRefreshToken(JSON.stringify(refreshTokenObj));
  AuthService.setBearer(accessToken);
  $store.commit('authState/SET_ATOKEN_EXP_DATE', exp);
}

function _getRefreshToken() {
  try {
    return JSON.parse(localStorage.getItem('refreshToken'));
  } catch {
    return null;
  }
}

function _getFingerprint() {
  return new Promise((resolve, reject) => {
    async function getHash() {
      const options = {
        excludes: {
          plugins: true,
          localStorage: true,
          adBlock: true,
          screenResolution: true,
          availableScreenResolution: true,
          enumerateDevices: true,
          pixelRatio: true,
          doNotTrack: true,
          preprocessor: (key, value) => {
            if (key === 'userAgent') {
              const parser = new UAParser(value);
              // return customized user agent (without browser version)
              return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`;
            }
            return value;
          },
        },
      };

      try {
        const components = await Fingerprint2.getPromise(options);
        const values = components.map((component) => component.value);

        return String(Fingerprint2.x64hash128(values.join(''), 31));
      } catch (e) {
        reject(e);
      }
    }

    if (window.requestIdleCallback) {
      requestIdleCallback(async () => resolve(await getHash()));
    } else {
      setTimeout(async () => resolve(await getHash()), 500);
    }
  });
}

async function _afterLogin() {
  const user = await UsersService.getCurrent();
  const { id, name } = UsersService.getCompany(user.data);
  $store.commit('userState/SET_CURRENT_COMPANY', name);
  $store.commit('userState/SET_CURRENT_COMPANY_ID', id);
  $store.dispatch('enumState/setBanksList');
  $store.dispatch('enumState/setCurrencyList');
  $store.dispatch('enumState/setPeriodicList');

  ArticleService.getArticleList();
  ContragentService.getContragentList();
  ProjectService.getProjectList();
  CardService.getCardsList();
}
