import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { IUserState } from '../../interfaces';
import { UsersService } from '@/services/users.service';
import { UserInfoDto } from '@/dto/User.dto';
import { CompanyDto } from '@/dto/Company.dto';

export const actions: ActionTree<IUserState, RootState> = {
  setCurrentRole({ commit }) {
    commit('SET_CURRENT_USER_ROLE', 'admin');
  },

  setCurrentCompany({ commit }) {
    UsersService.getCurrent()
      .then((user: UserInfoDto) => {
        const activeId = user.activeCompany;
        const activeCmp = user.companies.find((cmp: CompanyDto) => cmp.id === activeId);
        if (activeCmp === undefined) throw new Error('Not find active company');
        commit('SET_CURRENT_COMPANY', activeCmp.name);
      })
      .catch((error) => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
  },

  setCurrentCompanyId({ commit }) {
    UsersService.getCurrent()
      .then((user: UserInfoDto) => {
        commit('SET_CURRENT_COMPANY_ID', user.activeCompany);
      })
      .catch((error) => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
  },
};
