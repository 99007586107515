
import { Component, Prop, Vue } from 'vue-property-decorator';

import { UsersService } from '@/services/users.service';
import { UserInfoDto } from '@/dto/User.dto';
import Alert from '@/components/Alerts/alert.vue';

@Component
export default class UsersSettings extends Vue {
  @Prop() alert!: Alert;
  currentUserInfo: UserInfoDto = {} as UserInfoDto;
  companyUsers: UserInfoDto[] = [];

  async mounted() {
    await this.getCurrentUserInfo();
    await this.getUsersByCurrentCompany();
  }

  async getCurrentUserInfo(): Promise<void> {
    this.currentUserInfo = (await UsersService.getCurrent()).data;
  }

  async getUsersByCurrentCompany() {
    this.companyUsers = (await UsersService.getUsersByCompany()).data;
  }
}
