import axios from 'axios';

import { BaseService } from './base.service';
import { ResponseWrapper, ErrorWrapper } from './util';
import $store from '../store';

export class CompanyService extends BaseService {
  static async getAllCompanies() {
    try {
      const response = await this.request({ auth: true }).get('company');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async createCompany(name) {
    try {
      const response = await this.request({ auth: true }).post('company', { name });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async clearCompanyData() {
    try {
      const response = await this.request({ auth: true }).post('company/clear-data');
      $store.dispatch('filterList/clearFilters');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  // static async edit(action, data) {
  //   try {
  //     const response = await this.request({ auth: true }).post(`projects/${action}`, data)
  //     return new ResponseWrapper(response, response.data)
  //   } catch (error) {
  //     const message = error.response.data ? error.response.data.error : error.response.statusText
  //     throw new ErrorWrapper(error, message)
  //   }
  // }

  static async delete(ids) {
    try {
      const response = await this.request({ auth: true }).delete(`company`, { data: { ids } });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }
}
