import { BaseService } from './base.service';
import { ResponseWrapper, ErrorWrapper } from './util';
import $store from '../store';
import axios from 'axios';
import { ContragentDto } from '../dto/Contragent.dto';

export class ContragentService extends BaseService {
  static async getAllContragents(): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).get('contragents');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getContragentList(): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).get('contragents/list');
      $store.dispatch('contragentList/setContragentList', response.data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async edit(action: string, data: ContragentDto): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).post(`contragents/${action}`, data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async delete(ids: number[]): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).delete(`contragents`, { data: { ids } });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }
}
