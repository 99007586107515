
import { Component, Prop, Vue } from 'vue-property-decorator';

import { LogDto } from '@/dto/Log.dto';
import { LogService } from '@/services/log.service';
import Alert from '@/components/Alerts/alert.vue';

@Component
export default class LogTable extends Vue {
  @Prop() alert!: Alert;

  filteredLogs: LogDto[] = [];

  sortFields = [
    { key: 'createdAt', sortable: true },
    { key: 'type', sortable: true },
    { key: 'context', sortable: true },
    { key: 'message', sortable: true },
    { key: 'full', sortable: true },
  ];
  sortBy = 'createdAt';
  sortDesc = true;
  perPage = 50;
  currentPage = 1;
  rows = 0;

  async getLogs() {
    try {
      this.filteredLogs = (await LogService.getLogs()).data;
    } catch (error) {
      this.alert.error(this.formatErrorMessage(error));
    }
  }

  async clearLogs() {
    try {
      (await LogService.clearLogs()).data;
      await this.getLogs();
    } catch (error) {
      this.alert.error(this.formatErrorMessage(error));
    }
  }
}
