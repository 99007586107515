import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LocaleFormat extends Vue {
  localeDate(date: Date | string | null) {
    if (!date) {
      return 'not paid';
    }
    return new Date(date).toLocaleString(this.$store.getters.locale);
  }
}
