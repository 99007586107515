import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { IFilterState } from '../../interfaces';

export const getters: GetterTree<IFilterState, RootState> = {
  allFilters(state) {
    const filterData = localStorage.getItem('filterList');
    return filterData ? filterData : state;
  },

  getFilter: (state) => (filterName: string) => {
    const filterData = localStorage.getItem('filterList');
    return filterData && filterData[filterName] ? filterData['filterName'] : state[filterName];
  },
};
