import { MutationTree } from 'vuex';
import { IFilterState } from '../../interfaces';
import { defaultSate } from './defaultState';

export const mutations: MutationTree<IFilterState> = {
  SET_FILTER(state, filterOptions) {
    console.log(filterOptions.filterName);
    state[filterOptions.filterName] = filterOptions.filterData;
    localStorage.setItem('filterList', JSON.stringify(state));
  },
  SET_ALL_FILTERS(state, filters) {
    localStorage.setItem('filterList', JSON.stringify(filters));
    for (const filterName in filters) {
      state[filterName] = filters[filterName];
    }
  },
  CLEAR_FILTERS(state) {
    for (const filterName in defaultSate) {
      state[filterName] = defaultSate[filterName];
    }
    localStorage.setItem('filterList', JSON.stringify(state));
  },
};
