import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MapEnums extends Vue {
  mapEnums(en) {
    return Object.keys(en).map((k) => ({
      id: k,
      label: en[k],
    }));
  }
}
