
import { Component, Prop, Vue } from 'vue-property-decorator';

import { InviteService } from '@/services/invite.service';
import { InviteUserDto, TMyInvitations, TSendedInvite } from '@/dto/Invite.dto';
import Alert from '@/components/Alerts/alert.vue';

@Component
export default class InviteSettings extends Vue {
  @Prop() alert!: Alert;
  inviteEmail = '';
  myInvitations: TMyInvitations[] = [];
  sendedInvitations: TSendedInvite[] = [];

  async mounted() {
    await this.getMyInvitations();
    await this.getSendedInvitations();
  }

  async sendInvite() {
    const regexpEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (regexpEmail.test(this.inviteEmail)) {
      const form: InviteUserDto = { inviteEmail: this.inviteEmail };
      try {
        await InviteService.inviteUser(form);
      } catch (error) {
        this.alert.error(this.formatErrorMessage(error));
      }
    } else {
      console.log('in send error mail', this.formatErrorMessage('Wrong email'));
      this.alert.error(this.formatErrorMessage('Wrong email'));
    }
  }

  async getMyInvitations() {
    const result = await InviteService.getMyInvitations();
    this.myInvitations = result.data;
  }

  async getSendedInvitations() {
    const result = await InviteService.getSendedInvitations();
    this.sendedInvitations = result.data;
  }

  async acceptInvite(token: string) {
    try {
      this.myInvitations = (await InviteService.postAcceptInvite(token)).data;
    } catch (error) {
      this.alert.error(this.formatErrorMessage(error));
    }
  }

  async declineInvite(token: string) {
    try {
      this.myInvitations = (await InviteService.postDeclineInvite(token)).data;
    } catch (error) {
      this.alert.error(this.formatErrorMessage(error));
    }
  }

  async dropInvite(token: string) {
    try {
      await InviteService.postDropInvite(token);
      await this.getSendedInvitations();
    } catch (error) {
      this.alert.error(this.formatErrorMessage(error));
    }
  }
}
