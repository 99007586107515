import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { initCurrentUserStateMiddleware, checkAccessMiddleware, setPageTitleMiddleware } from './middlewares';

// import DashboardIndex from '../views/dashboard/Index.vue'
// import Dashboard from '../views/dashboard/Dashboard.vue'
// import UserProfile from '../views/dashboard/pages/UserProfile.vue'
// import DashboardNotifications from '../views/dashboard/component/Notifications.vue'
// import DashboardIcons from '../views/dashboard/component/Icons.vue'
// import Typography from '../views/dashboard/component/Typography.vue'
// import RegularTables from '../views/dashboard/tables/RegularTables.vue'
// import GoogleMaps from '../views/dashboard/maps/GoogleMaps.vue'

import AdminIndex from '@/views/admin/Index.vue';
import OtpAutoClient from '@/views/admin/importFiles/autoClient/OtpAutoClient.vue';
import PaysPage from '@/views/admin/pays/PaysPage.vue';
import ProjectsPage from '@/views/admin/projects/Projects.vue';
import AccountsPage from '@/views/admin/accounts/Accounts.vue';
import CardsPage from '@/views/admin/cards/Cards.vue';
import ArticlesPage from '@/views/admin/articles/Articles.vue';
import ContragentsPage from '@/views/admin/contragents/Contragents.vue';
import ImportFiles from '@/views/admin/importFiles/ImportFile.vue';
import LoginPage from '@/views/authorize/LoginPage.vue';
import PublicPage from '@/views/PublicPage.vue';
import SettingsPage from '@/views/admin/settings/SettingsPage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    name: 'public',
    path: '/',
    component: PublicPage,
    meta: { title: 'Main public page', isAuth: false },
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminIndex,
    meta: { title: 'Adminpanel', isAuth: true },
    children: [
      // Pages
      {
        name: 'pays',
        path: 'pays',
        component: PaysPage,
        meta: { title: 'Pays' },
      },
      {
        name: 'projects',
        path: 'projects',
        component: ProjectsPage,
        meta: { title: 'Projects' },
      },
      {
        name: 'accounts',
        path: 'accounts',
        component: AccountsPage,
        meta: { title: 'Accounts' },
      },
      {
        name: 'cards',
        path: 'cards',
        component: CardsPage,
        meta: { title: 'Cards' },
      },
      {
        name: 'contragents',
        path: 'contragents',
        component: ContragentsPage,
        meta: { title: 'Contragents' },
      },
      {
        name: 'articles',
        path: 'articles',
        component: ArticlesPage,
        meta: { title: 'Articles' },
      },
      {
        name: 'Import files',
        path: 'import-files',
        component: ImportFiles,
        meta: { title: 'Import files' },
      },
      {
        name: 'OTP AutoClient',
        path: 'auto-client',
        component: OtpAutoClient,
        meta: { title: 'AutoClient (OTP)' },
      },
      {
        path: 'settings',
        name: 'settings',
        component: SettingsPage,
        meta: { title: 'Settings', isAuth: true },
      },

      {
        path: 'page-not-found',
        alias: '*',
        component: { render: (h) => h('div', ['404! Page Not Found!']) },
      },

      // Dashboard
      // {
      //   name: 'Dashboard',
      //   path: '',
      //   component: Dashboard,
      // },
      // {
      //   name: 'User Profile',
      //   path: 'pages/user',
      //   component: UserProfile,
      // },
      // {
      //   name: 'Notifications',
      //   path: 'components/notifications',
      //   component: DashboardNotifications,
      // },
      // {
      //   name: 'Icons',
      //   path: 'components/icons',
      //   component: DashboardIcons,
      // },
      // {
      //   name: 'Typography',
      //   path: 'components/typography',
      //   component: Typography,
      // },
      // // Tables
      // {
      //   name: 'Regular Tables',
      //   path: 'tables/regular-tables',
      //   component: RegularTables,
      // },
      // // Maps
      // {
      //   name: 'Google Maps',
      //   path: 'maps/google-maps',
      //   component: GoogleMaps,
      // },
      // {
      //   name: 'logout',
      //   path: 'logout',
      //   redirect: '/login'
      // },
    ],
  },
  {
    name: 'login',
    path: '/login',
    component: LoginPage,
    meta: { title: 'Login form', isAuth: false },
  },
  {
    path: '/page-not-found',
    alias: '*',
    component: { render: (h) => h('div', ['404! Page Not Found!']) },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(initCurrentUserStateMiddleware);
router.beforeEach(checkAccessMiddleware);
router.beforeEach(setPageTitleMiddleware);

export default router;
