import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { IContragentListState } from '@/store/interfaces';
import { ContragentDto } from '@/dto/Contragent.dto';

export const getters: GetterTree<IContragentListState, RootState> = {
  getContragentList(state): ContragentDto[] {
    return state.contragentList || [];
  },
};
