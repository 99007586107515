import { MutationTree } from 'vuex';
import { IBalanceState } from '@/store/interfaces';
import { TCurrencyBalance } from '@/dto/Pays.dto';

export const mutations: MutationTree<IBalanceState> = {
  SET_COMPANY_BALANCE: (state, value: TCurrencyBalance) => (state.balance = value),
  SET_BALANCE_OF_SELECTED: (state, value: TCurrencyBalance) => {
    state.balanceOfSelected = value;
  },
  DEFAULT_BALANCE_OF_SELECTED: (state) => (state.balanceOfSelected = null),
};
