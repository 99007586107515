import { Module } from 'vuex';
import { mutations } from './mutations';
import { RootState } from '@/store/types';
import { IDashboardState } from '../../interfaces';

export const state: IDashboardState = {
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  barImage: '',
  drawer: null,
};

const namespaced = true;

export const dashboardState: Module<IDashboardState, RootState> = {
  namespaced,
  state,
  mutations,
};
