import { BaseService } from './base.service';
import { ResponseWrapper, ErrorWrapper } from './util';

export class AutoClientService extends BaseService {
  static async getFileList(source) {
    try {
      const [host, share, ...dirs] = source.split('/');
      const path = dirs.join('/');
      const request = {
        host,
        share,
        path,
      };
      const response = await this.request({ auth: true }).post('auto-client/smb-file-list', { ...request });
      const data = response.data;
      return new ResponseWrapper(response, {
        success: true,
        fileList: [...data.data],
      });
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async importFile(request) {
    try {
      const response = await this.request({ auth: true }).post('auto-client/import-file', { ...request });
      const data = response.data;
      const importStatus = {
        all: +data.data.imported + data.data.ignored.length + data.errors.length,
        imported: data.data.imported,
        ignored: data.data.ignored.length,
        errored: data.errors.length,
      };

      return new ResponseWrapper(response, { importStatus, fileAnswer: data });
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
