import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { IUserState } from '../../interfaces';

export const getters: GetterTree<IUserState, RootState> = {
  permissions() {
    // todo add RBAC with roles & permissions
    // return state.currentUserRole.permissions.map((p) => p.name);
    return ['admin'];
  },
  currentCompany(state) {
    return state.currentCompany;
  },
  currentCompanyId(state) {
    return state.currentCompanyId;
  },
  currentUserRole(state) {
    return state.currentUserRole;
  },
};
