
import { Component, Prop, Vue } from 'vue-property-decorator';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { State } from 'vuex-class';

import { AccountDto } from '@/dto/Account.dto';
import { CardDto } from '@/dto/Card.dto';

@Component({
  components: {
    Treeselect,
  },
})
export default class ModalAccountEdit extends Vue {
  @Prop() formEdit!: AccountDto;
  @Prop() modalAction!: string;

  @State('cardList', { namespace: 'cardList' }) cardsList!: CardDto[];

  titleWindow() {
    return `${this.modalAction.charAt(0).toUpperCase() + this.modalAction.slice(1)} Account`;
  }

  checkFormValidity() {
    return (this.$refs.form as Vue & { checkValidity: () => boolean }).checkValidity();
  }

  handleOk(e) {
    e.preventDefault();
    this.handleSubmit();
  }

  handleSubmit() {
    if (!this.checkFormValidity()) return;
    this.$nextTick(() => {
      this.$bvModal.hide('modal-account-edit');
    });
    this.$emit('editModal', this.modalAction, {
      ...this.formEdit,
    });
  }
}
