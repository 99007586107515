
import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import moment from 'moment';

import { TLists } from '@/dto/base.dto';
import { TDividePay } from '@/dto/Pays.dto';

@Component({
  components: { Treeselect },
})
export default class DividedPayForm extends Vue {
  @PropSync('dividedPay') pay!: TDividePay;
  @Prop() balance!: number;
  @Prop() rowSumOplata!: number;
  @Prop() lists!: TLists;

  max = 0;
  min = 0;
  mayApproved = true;

  created() {
    this.max = +this.rowSumOplata > 0 && +this.balance >= 0 ? +this.balance + (this.pay?.sumOplata ?? 0) : 0;
    this.min = +this.rowSumOplata < 0 && +this.balance <= 0 ? (this.pay?.sumOplata ?? 0) + this.balance : 0;
    this.mayApproved = moment(this.pay.dateTimeAccrual).isBefore(moment());
  }

  @Watch('pay.sumOplata')
  onSumOplataChange() {
    this.$emit('checkBalance');
  }

  @Watch('pay.dateTimeAccrual')
  onDateAccrualChange() {
    this.mayApproved = moment(this.pay.dateTimeAccrual).isBefore(moment());
    if (!this.mayApproved) this.pay.isApproved = false;
  }

  @Watch('balance')
  onBalanceChange() {
    this.max = +this.rowSumOplata > 0 && +this.balance >= 0 ? +this.balance + (this.pay?.sumOplata ?? 0) : 0;
    this.min = +this.rowSumOplata < 0 && +this.balance <= 0 ? (this.pay?.sumOplata ?? 0) + this.balance : 0;
  }

  deletePay() {
    this.$emit('deletePay');
    return true;
  }
}
