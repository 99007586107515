
import { Component, Prop, Vue } from 'vue-property-decorator';

import Alert from '@/components/Alerts/alert.vue';
import { CompanyDto } from '@/dto/Company.dto';
import { CompanyService } from '@/services/company.service';
import { UsersService } from '@/services/users.service';
import { BalanceService } from '@/services/balance.service';

@Component
export default class CompanySettings extends Vue {
  @Prop() alert!: Alert;
  myCompanies: CompanyDto[] = [];
  newCompanyName = '';
  actionInProgress = false;

  async mounted() {
    await this.getMyCompanies();
    this.actionInProgress = false;
  }

  async getMyCompanies() {
    this.myCompanies = (await CompanyService.getAllCompanies()).data;
  }

  async createCompany() {
    await CompanyService.createCompany(this.newCompanyName);
    await this.getMyCompanies();
  }

  maySetActive(company: CompanyDto): boolean {
    return company.id !== this.$store.state.userState.currentCompanyId;
  }

  async setActive(company: CompanyDto): Promise<void> {
    await UsersService.changeActiveCompany(company.id);
  }

  async leaveCompany(company: CompanyDto): Promise<void> {
    try {
      await UsersService.leaveCompany(company.id);
      await this.getMyCompanies();
    } catch (error) {
      this.alert.error(this.formatErrorMessage(error));
    }
  }

  async deleteCompany(company: CompanyDto): Promise<void> {
    try {
      await CompanyService.delete([company.id]);
      await this.getMyCompanies();
    } catch (error) {
      this.alert.error(this.formatErrorMessage(error));
    }
  }

  async clearCompanyData() {
    try {
      this.actionInProgress = true;
      await CompanyService.clearCompanyData();
      this.actionInProgress = false;
      // todo clear balance store
      this.alert.success('Data cleared');
      await this.getMyCompanies();
    } catch (error) {
      this.alert.error(this.formatErrorMessage(error));
    }
  }

  async recalcBalanceCompany(company: CompanyDto): Promise<void> {
    try {
      this.actionInProgress = true;
      await BalanceService.recalcBalance(company);
      this.actionInProgress = false;
      this.alert.success('Balance been recalculated.');
    } catch (error) {
      this.alert.error(this.formatErrorMessage(error));
    }
  }
}
