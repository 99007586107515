<template>
  <b-modal id="modal-card-get-pays" size="md" :title="titleWindow" @ok="handleOk">
    <div>
      <el-date-picker
        v-model="dateRange"
        type="daterange"
        align="center"
        unlink-panels
        first-day-of-week="1"
        format="dd.MM.yyyy"
        range-separator=" - "
        start-placeholder="Start date"
        end-placeholder="End date"
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerOptions"
      ></el-date-picker>
    </div>
  </b-modal>
</template>

<script>
// import moment from 'moment';
import moment from 'moment-timezone';

export default {
  name: 'GetPaysForm',
  props: ['card'],
  data() {
    return {
      titleWindow: 'Get pays',
      dateRange: {
        startDate: null,
        endDate: null,
      },

      pickerOptions: {
        shortcuts: [
          {
            text: 'Last week',
            onClick(picker) {
              const tz = moment.tz.guess();
              const end = moment(new Date()).tz(tz).endOf('day');
              const start = moment(new Date()).tz(tz).subtract(7, 'days').startOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const tz = moment.tz.guess();
              const end = moment(new Date()).tz(tz).endOf('day');
              const start = moment(new Date()).tz(tz).subtract(1, 'month').startOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    handleOk(e) {
      e.preventDefault();
      this.handleSubmit();
    },

    handleSubmit() {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-card-get-pays');
      });
      this.$emit('getPays', {
        cardId: this.card.id,
        from: this.dateRange[0],
        to: this.dateRange[1],
      });
    },
  },
};
</script>
