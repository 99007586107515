<template>
  <div>
    <vue-confirm-dialog></vue-confirm-dialog>
    <b-pagination
      v-model="currentPage"
      :total-rows="accounts.length || 0"
      :per-page="perPage"
      first-number
      last-number
      aria-controls="accounts-table"
    ></b-pagination>

    <b-table
      v-model="visibleRows"
      responsive
      striped
      hover
      sort-icon-left
      name="accounts-table"
      :items="accounts"
      :fields="sortFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :per-page="perPage"
      :current-page="currentPage"
      :busy="isBusy"
      @row-clicked="onRowClicked"
    >
      <template #cell(actions)="row">
        <b-button class="mr-3" variant="warning" @click="$emit('fillModal', row.item, 'edit')">Edit</b-button>
        <b-button variant="danger" @click="$emit('deleteAccount', row.item.id)">Delete</b-button>
      </template>

      <template #cell(updatedAt)="data">
        {{ localeDate(data.item.updatedAt) }}
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="accounts.length || 0"
      :per-page="perPage"
      first-number
      last-number
      aria-controls="accounts-table"
    ></b-pagination>
  </div>
</template>

<script>
import { AccountService } from '@/services/account.service';

export default {
  name: 'AccountsTable',
  props: ['accounts'],
  data: () => ({
    sortAsc: true,
    sortFields: [
      { key: 'name', sortable: true },
      { key: 'initialBalance', sortable: true },
      { key: 'updatedAt', sortable: true },
      { key: 'description', sortable: false },
      { key: 'actions', sortable: false },
    ],
    sortBy: 'name',
    sortDesc: false,
    perPage: 10,
    currentPage: 1,
    rows: 0,
    isBusy: false,
    visibleRows: [],
    selectedAccount: null,
  }),
  methods: {
    onRowClicked(item) {
      this.selectedAccount = item;
      this.$emit('rowClick', this.selectedAccount.id);
    },

    changeTableRowCount(rows) {
      if (rows === 'all') {
        this.perPage = this.accounts.length;
      } else {
        this.perPage = rows;
      }
    },

    cardNames(row) {
      return row.cards.map((elm) => elm.name);
    },

    async deleteAccount(item) {
      this.$confirm({
        message: 'Are you sure delete Account?',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async (confirm) => {
          if (confirm) {
            const answer = await AccountService.delete(item.id);
            if (answer.data.errors) {
              const errorMessages = answer.data.errors.map(
                (err) => `Account with id ${err.id} not deleted. ${err.message}`,
              );
              this.$emit('showAlert', 'danger', errorMessages.join('\n'));
            } else {
              this.$emit('showAlert', 'success', 'Account(s) deleted!');
            }
            await this.getAllAccounts();
          }
        },
      });
    },
  },
};
</script>
