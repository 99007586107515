import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { IContragentListState } from '@/store/interfaces';
import { ContragentDto } from '@/dto/Contragent.dto';

export const actions: ActionTree<IContragentListState, RootState> = {
  setContragentList({ commit }, value: ContragentDto[]): void {
    commit('SET_CONTRAGENT_LIST', value);
  },
};
