
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import MonoCreateForm from './_MonoCreateForm.vue';
import MonoEditForm from './_MonoEditForm.vue';
import { State } from 'vuex-class';
import { CardDto } from '@/dto/Card.dto';
import { CurrencyCode } from '@/dto/enums';
import { TTag } from '../../types/tag.type';

@Component({
  components: { MonoCreateForm, MonoEditForm, Treeselect },
})
export default class ModalEdit extends Vue {
  @Prop() form;
  @Prop() modalAction!: string;

  @State('banksList', { namespace: 'enumState' }) bankList!: Record<string, string>;

  @State('currencyList', { namespace: 'enumState' }) currencyList!: Record<string, string>;

  formTmp: CardDto = {
    id: 0,
    isActive: true,
    name: '',
    bank: '',
    cardNum: '',
    currency: CurrencyCode.UAH,
    iban: '',
    mfo: '',
    initialBalance: 0,
    balance: 0,
    description: '',
    typeCardCreate: 'auto',
    accounts: [],
    cards: [],
  };
  accountsList: TTag[] = [];
  titleWindow = '';

  async mounted() {
    // this.accountsList = (await AccountService.getShortList()).data;
    this.titleWindow = this.getTitleWindow();
  }

  @Watch('form')
  formChange() {
    this.formTmp = { ...this.form };
  }

  getTitleWindow() {
    let title = `${this.modalAction.charAt(0).toUpperCase() + this.modalAction.slice(1)} Card`;
    if (this.modalAction == 'edit') {
      title += ` - ${this.form.name}`;
    }
    return title;
  }

  checkFormValidity() {
    return (this.$refs.form as Vue & { checkValidity: () => boolean }).checkValidity();
  }

  handleOk(e) {
    e.preventDefault();
    this.handleSubmit();
  }

  handleSubmit() {
    if (!this.checkFormValidity()) return;
    this.$nextTick(() => {
      this.$bvModal.hide('modal-card-edit');
    });
    this.$emit('editModal', this.modalAction, {
      ...this.formTmp,
    });
  }

  addTag(newTag: string) {
    const tag = {
      name: newTag,
      code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    };
    this.accountsList.push(tag);
  }
}
