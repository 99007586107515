import { BaseService } from './base.service';
import { ResponseWrapper, ErrorWrapper } from './util';
import $store from '../store';
import axios from 'axios';
import { ArticleNewDto } from '../dto/Article.dto';

export class ArticleService extends BaseService {
  static async getArticleList(): Promise<ResponseWrapper> {
    try {
      const response = await this.request({ auth: true }).get('articles');
      $store.dispatch('articleList/setArticleList', response.data);

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async insert(data: ArticleNewDto): Promise<ResponseWrapper> {
    try {
      const response = await this.request({ auth: true }).post(`articles`, data);
      $store.dispatch('articleList/setArticleList', response.data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async move(src: number, dst: number | null): Promise<ResponseWrapper> {
    try {
      const response = await this.request({ auth: true }).put(`articles/move`, {
        id: src,
        newParent: dst,
      });
      $store.dispatch('articleList/setArticleList', response.data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async delete(ids: number[]): Promise<ResponseWrapper> {
    try {
      const response = await this.request({ auth: true }).delete(`articles`, { data: { ids } });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }
}
