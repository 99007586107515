import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { IArticleListState } from '@/store/interfaces';
import { ArticleDto } from '../../../dto/Article.dto';

export const getters: GetterTree<IArticleListState, RootState> = {
  getArticleList(state): ArticleDto[] {
    return state.articleList || [];
  },
};
