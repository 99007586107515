
import { Component, Emit, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { CardService } from '@/services/card.service';

import ModalEdit from '@/components/Cards/ModalEdit.vue';
import GetPaysForm from '@/components/Cards/GetPaysForm.vue';
import CardsTable from '@/components/Cards/CardsTable.vue';
import Alert from '@/components/Alerts/alert.vue';

import { TAlertType } from '@/types/alert.type';

import { CardNewDto, CardDto } from '@/dto/Card.dto';
import { CurrencyCode } from '@/dto/enums';

@Component({
  components: { CardsTable, ModalEdit, GetPaysForm, Alert },
})
export default class CardsPage extends Vue {
  @State('banksList', { namespace: 'enumState' }) bankList!: Record<string, string>;

  accounts = [];
  cards = [];
  selectedCard = {};
  alertMessage: TAlertType = {} as TAlertType;

  editCardData: CardDto | CardNewDto = {
    id: 0,
    isActive: true,
    name: '',
    bank: '',
    cardNum: '',
    currency: CurrencyCode.UAH,
    iban: '',
    mfo: '',
    initialBalance: 0,
    balance: 0,
    description: '',
    typeCardCreate: 'auto',
    accounts: [],
  };
  modalAction = 'add';

  async mounted() {
    await this.getAllCards();
    this.alertMessage = this.$refs.alert as TAlertType;
  }
  async getAllCards() {
    this.cards = (await CardService.getAllCards()).data;
  }

  fillCardsForm(cardData, action = 'add') {
    if (action === 'add') {
      this.editCardData = {
        isActive: true,
        name: '',
        bank: '',
        cardNum: '',
        currency: CurrencyCode.UAH,
        iban: '',
        mfo: '',
        initialBalance: 0,
        balance: 0,
        description: '',
        typeCardCreate: 'auto',
        accounts: [],
      } as CardNewDto;
    } else {
      const banks = this.bankList;
      const bank = Object.keys(banks).find((key) => banks[key] === cardData.bank) ?? 'UNKNOWN';
      this.editCardData = { ...cardData, bank };
    }
    this.modalAction = action;
    this.$bvModal.show('modal-card-edit');
  }

  getPaysFormShow(card) {
    this.selectedCard = card;
    this.$bvModal.show('modal-card-get-pays');
  }

  async getPays(data) {
    try {
      const answer = await CardService.getPays(data);
      this.alertMessage.success(answer.statusMessage);
      await this.getAllCards();
    } catch (e) {
      console.error(e);
      // this.showModalInfo(e.response.data.message, 'danger');
    }
  }

  @Emit()
  async editCard(action, formData) {
    try {
      const answer = await CardService.edit(action, formData);
      this.alertMessage.success(answer.statusMessage);
      await this.getAllCards();
    } catch (e) {
      console.error(e);
      // this.showModalInfo(e.response.data.message, 'danger');
    }
  }

  async deleteCard(items) {
    this.$confirm({
      message: 'Are you sure delete Card?',
      button: {
        no: 'No',
        yes: 'Yes',
      },
      /**
       * Callback Function
       * @param {Boolean} confirm
       */
      callback: async (confirm) => {
        if (confirm) {
          const ids = items.map((item) => item.id);
          const answer = await CardService.delete(ids);
          if (answer.data.errors.length > 0) {
            const errorMessages = answer.data.errors.map((err) => `Card with id ${err.id} not deleted. ${err.message}`);
            this.alertMessage.danger(errorMessages.join('\n'));
          } else {
            this.alertMessage.success('Card(s) deleted!');
          }
          //todo maybe change delete to unset successed ids
          this.getAllCards();
        }
      },
    });
  }
}
