<template>
  <h1>Money Plan</h1>
</template>

<script>
export default {
  name: 'PublicPage',
  mounted() {
    this.$router.push({ path: '/admin/pays' }).catch((e) => {
      console.log(e);
      return;
    });
  },
};
</script>
