import { BaseService } from './base.service';
import { ResponseWrapper, ErrorWrapper } from './util';
import axios from 'axios';

export class AccountService extends BaseService {
  static async getAllAccounts(): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).get('accounts');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getCardsByAccount(accountId: number): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).get(`accounts/${accountId}/cards`);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async edit(action: string, data): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).post(`accounts/${action}`, data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async delete(ids: number[]): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).delete(`accounts`, { data: { ids } });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  // static async getShortList(): ResponseWrapper {
  //   try {
  //     const response = await this.request({ auth: true }).get(`accounts/short-list`);
  //     return new ResponseWrapper(response, response.data);
  //   } catch (error) {
  //     const message = axios.isAxiosError(error) ? error.message : error;
  //     throw new ErrorWrapper(error, message);
  //   }
  // }
}
