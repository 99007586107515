import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { IBalanceState } from '@/store/interfaces';
import { TCurrencyBalance } from '@/dto/Pays.dto';
import { BalanceService } from '@/services/balance.service';

export const actions: ActionTree<IBalanceState, RootState> = {
  setCompanyBalance({ commit }) {
    BalanceService.getCompanyBalance()
      .then((balance: TCurrencyBalance) => {
        commit('SET_COMPANY_BALANCE', balance.data);
      })
      .catch((error) => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
  },
  setSelectedBalance({ commit }, value: TCurrencyBalance) {
    commit('SET_BALANCE_OF_SELECTED', value);
  },
  setDefaultBalance({ commit }) {
    commit('DEFAULT_BALANCE_OF_SELECTED');
  },
};
