<template>
  <div class="container">
    <LoginForm />

    <v-divider></v-divider>

    <RegisterForm />
  </div>
</template>

<script>
import { AuthService } from '@/services/auth.service';

import LoginForm from '@/components/Auth/LoginForm';
import RegisterForm from '@/components/Auth/RegisterForm';

export default {
  name: 'LoginPage',
  components: { LoginForm, RegisterForm },
  beforeMount() {
    if (AuthService.hasRefreshToken())
      return this.$router.push({ name: 'admin' }).catch((e) => {
        console.log(e);
      });
  },
};
</script>

<style scoped>
.container {
  width: 450px;
  margin-top: 20px !important;
  text-align: center;
}
</style>
