<template>
  <div>
    Choose file(s):

    <b-form-file
      v-model="fileList"
      accept=".csv, .xls, .xlsx, text/csv, application/csv,
          text/comma-separated-values, application/csv, application/excel,
          application/vnd.msexcel, application/vnd. ms-excel,
          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      placeholder="Choose a file or drop it here..."
      multiple
      drop-placeholder="Drop file here..."
      :state="Boolean(fileList)"
    ></b-form-file>
    <b-form-select v-model="selectedBank" :options="optionsBankList"></b-form-select>
    <div class="mt-3">
      Selected file:
      {{ fileNames() }}
    </div>
    <b-button
      variant="warning"
      :disabled="!selectedBank || fileList.length == 0 || importInProgress"
      @click="importFiles()"
    >
      <span v-if="importInProgress">Importing...</span>
      <span v-else>Import</span>
    </b-button>
    <div>
      {{ importFileStatistic }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ImportFileService } from '@/services/importFile.service';
import { ImportSourceList } from '@/dto/enums';
export default Vue.extend({
  name: 'ImportFileForm',
  data() {
    return {
      fileList: [],
      selectedBank: null,
      optionsBankList: ImportSourceList,
      importInProgress: false,
      importFileStatistic: {
        selected: 0,
        imported: 0,
        ignored: 0,
        errored: 0,
      },
    };
  },
  methods: {
    fileNames() {
      return this.fileList.length > 0
        ? this.fileList
            .map((file) => {
              return file?.name ?? '';
            })
            .join(', ')
        : '';
    },

    async importFiles() {
      const promises = [];
      this.importInProgress = true;
      for (const fileInfo of this.fileList) {
        const request = new FormData();
        request.append('bank', this.selectedBank);
        request.append('files', fileInfo);

        const promise = new Promise((resolve) => {
          ImportFileService.importFile(request)
            .then((response) => {
              // const { importStatus, fileAnswer } = response.data;
              this.importFileStatistic = { ...response.data };
              // if (importStatus.errored) {
              //   compon.importFileStatistic.errored++;
              // } else {
              //   if (importStatus.ignored === importStatus.all) {
              //     compon.importFileStatistic.ignored++;
              //   } else {
              //     compon.importFileStatistic.imported++;
              //   }
              // }

              // const importStatFileList = compon.fileList.map((elem) => {
              //   if (elem.filename === fileInfo.filename) {
              //     return {
              //       ...elem,
              //       status: fileAnswer.fileStatus,
              //       importAnswer: fileAnswer,
              //       importStatus,
              //     };
              //   }
              //   return elem;
              // });
              // compon.fileList = importStatFileList;
              resolve();
            })
            .catch((err) => {
              console.error(err);
            });
        });
        promises.push(promise);
      }

      await Promise.all(promises);
      this.importInProgress = false;
    },
  },
});
</script>
