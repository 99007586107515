import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { INavigationState } from '../../interfaces';

export const getters: GetterTree<INavigationState, RootState> = {
  getDrawerState(state) {
    const storageData = localStorage.getItem('navigationList');
    let drawer = state.drawer;
    if (storageData) {
      drawer = JSON.parse(storageData).drawer;
    }
    return drawer;
  },
  getMiniState(state) {
    return state.mini;
  },
  getPanelState(state) {
    return state.panel;
  },
};
