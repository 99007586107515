import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { IDomState } from '../../interfaces';

export const getters: GetterTree<IDomState, RootState> = {
  isExtraSmall: (state) => state.windowWidth <= state.widthExtraSmall,
  isSmall: (state) => state.windowWidth <= state.widthSmall,
  isTablet: (state) => state.windowWidth <= state.widthTablet,
  isDesktop: (state) => state.windowWidth > state.widthTablet,
};
