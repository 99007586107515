
import Vue from 'vue';
import FileListTable from '@/components/FileTable/Table.vue';

export default Vue.extend({
  name: 'OtpAutoClient',
  components: { FileListTable },
  data() {
    return {
      sources: '10.0.53.240/AutoClient/Export_Documents;10.0.53.240/AutoClient/241/Export_Documents'.split(';'),
    };
  },
  methods: {
    updateAllFileList() {
      for (const srcId in this.$refs.sources) {
        this.$refs.sources[srcId].updateFileList();
      }
    },
  },
});
