<template>
  <div>
    <ActionPallet
      :source="source"
      :import-files-status="importFilesStatus"
      :import-file-statistic="importFileStatistic"
      @applayFilter="applayFilter"
      @changeTableRowCount="changeTableRowCount"
      @importFiles="importFiles"
    />
    <b-alert
      dismissible
      variant="danger"
      :show="dismissCountDown"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p>{{ alertMessage }}</p>
      <b-progress variant="danger" height="4px" :max="dismissSecs" :value="dismissCountDown"></b-progress>
    </b-alert>

    <b-pagination
      v-model="currentPage"
      first-number
      last-number
      :total-rows="filteredList.length || 0"
      :per-page="perPage"
      :aria-controls="tableName"
    ></b-pagination>

    <b-table
      v-model="visibleRows"
      responsive
      striped
      hover
      sort-icon-left
      :name="tableName"
      :items="filteredList"
      :fields="sortFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :per-page="perPage"
      :current-page="currentPage"
      :busy="isBusy"
      @row-clicked="toggleDetails"
    >
      <template #cell(numRow)="row">
        {{ (currentPage - 1) * perPage + row.index + 1 }}
      </template>
      <template #head(selected)="">
        <input v-model="selectAll" type="checkbox" @click="select" />
      </template>
      <template #cell(selected)="row">
        <b-form-group>
          <input v-model="selected" type="checkbox" :value="row.item" />
        </b-form-group>
      </template>

      <template #cell(rowCount)="row">
        <b-progress v-if="row.item.rowCount" class="mt-2" show-value :max="row.item.rowCount">
          <b-progress-bar :value="row.item.rowCount" variant="info"></b-progress-bar>
        </b-progress>

        <b-progress v-if="row.item.importStatus" class="mt-2" show-value :max="row.item.importStatus.all">
          <b-progress-bar :value="row.item.importStatus.imported" variant="success"></b-progress-bar>
          <b-progress-bar :value="row.item.importStatus.ignored" variant="warning"></b-progress-bar>
          <b-progress-bar variant="danger" :value="row.item.importStatus.errored"></b-progress-bar>
        </b-progress>
      </template>
      <template #row-details="{ item }">
        <div @click="toggleDetails(item)">
          <ImportMessageCard
            v-if="item.importStatus.ignored > 0"
            title="Ignored"
            status="warning"
            :datas="item.importAnswer.data.ignored"
          />
          <ImportMessageCard
            v-if="item.importStatus.errored > 0"
            title="Errored"
            status="danger"
            :datas="item.importAnswer.errors"
          />
        </div>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      first-number
      last-number
      :total-rows="filteredList.length || 0"
      :per-page="perPage"
      :aria-controls="tableName"
    ></b-pagination>
  </div>
</template>

<script>
import { AutoClientService } from '@/services/autoClient.service';
import ActionPallet from './ActionPallet.vue';
import ImportMessageCard from './ImportMessageCard.vue';

export default {
  name: 'FileListTable',
  components: { ActionPallet, ImportMessageCard },
  props: ['source', 'index'],
  data() {
    return {
      fileList: [],
      sortBy: 'filename',
      sortDesc: false,
      sortFields: [
        { key: 'numRow', sortable: false },
        { key: 'selected', sortable: false },
        { key: 'rowCount', sortable: false },
        {
          key: 'filename',
          sortable: true,
        },
        {
          key: 'status',
          sortable: true,
        },
      ],
      perPage: 10,
      currentPage: 1,
      rows: 0,
      tableName: '',
      filter: {
        filterName: '',
        value: '',
      },
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      alertMessage: '',
      selected: [],
      selectAll: false,
      isBusy: false,
      importFilesStatus: false,
      visibleRows: [],
      importFileStatistic: {
        selected: 0,
        imported: 0,
        ignored: 0,
        errored: 0,
      },
    };
  },

  computed: {
    filteredList() {
      if (this.filter.filterName === 'status') {
        return this.filter.value === 'all'
          ? this.fileList
          : this.fileList.filter((item) => item.status === this.filter.value);
      }
      return this.fileList;
    },
  },
  mounted() {
    this.tableName = `file-table-${this.index}`;
  },

  methods: {
    applayFilter(params) {
      this.filter = params;
      this.currentPage = 1;
      this.selectAll = false;
      this.selected = [];
    },

    updateFileList: async function () {
      try {
        this.isBusy = true;
        this.fileList = [];
        this.selected = [];
        this.selectAll = false;
        const response = await AutoClientService.getFileList(this.source);
        const data = response.data;
        this.fileList = data.fileList.map((elem) => ({
          ...elem,
          importAnswer: null,
          importStatus: null,
        }));
        if (!data.success) throw data.error;
      } catch (e) {
        this.alertMessage = e;
        this.showAlert();
      } finally {
        this.rows = this.fileList.length;
        this.isBusy = false;
      }
    },

    changeTableRowCount(rows) {
      this.selectAll = false;
      this.selected = [];
      if (rows === 'all') {
        this.perPage = this.fileList.length;
      } else {
        this.perPage = rows;
      }
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },

    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.visibleRows) {
          this.selected.push(this.visibleRows[i]);
        }
      }
    },

    toggleDetails(row) {
      if (!row._showDetails) {
        if (row.importStatus && (row.importStatus.ignored > 0 || row.importStatus.errored > 0)) {
          this.$set(row, '_showDetails', true);
        }
      } else {
        this.$set(row, '_showDetails', false);
      }
    },

    async importFiles() {
      const compon = this;

      compon.importFilesStatus = true;
      compon.importFileStatistic = {
        selected: compon.selected.length,
        imported: 0,
        ignored: 0,
        errored: 0,
      };

      const [host, share, ...dirs] = compon.source.split('/');
      const path = dirs.join('/');
      const request = {
        host,
        share,
        path,
        bank: 'otp',
        fileInfo: {},
      };
      const promises = [];

      for (const fileInfo of compon.selected) {
        request.fileInfo = fileInfo;
        const promise = new Promise((resolve) => {
          AutoClientService.importFile(request)
            .then((response) => {
              const { importStatus, fileAnswer } = response.data;

              if (importStatus.errored) {
                compon.importFileStatistic.errored++;
              } else {
                if (importStatus.ignored === importStatus.all) {
                  compon.importFileStatistic.ignored++;
                } else {
                  compon.importFileStatistic.imported++;
                }
              }

              const importStatFileList = compon.fileList.map((elem) => {
                if (elem.filename === fileInfo.filename) {
                  return {
                    ...elem,
                    status: fileAnswer.fileStatus,
                    importAnswer: fileAnswer,
                    importStatus,
                  };
                }
                return elem;
              });
              compon.fileList = importStatFileList;
              resolve();
            })
            .catch((err) => {
              console.log(err);
            });
        });
        promises.push(promise);
      }
      await Promise.all(promises);

      this.importFilesStatus = false;
      this.selectAll = false;
      this.selected = [];
    },
  },
};
</script>
