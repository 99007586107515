import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';
import { IFilterState } from '../../interfaces';
import { defaultSate } from './defaultState';

const storage = localStorage.getItem('filterList');
export const state = storage ? JSON.parse(storage) : defaultSate;

const namespaced = true;

export const filterList: Module<IFilterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
