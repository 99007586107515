const paginationOptions = {
  enabled: true,
  mode: 'pages',
  perPage: 50,
  position: 'top',
  perPageDropdown: [10, 20, 50, 100],
  dropdownAllowAll: true,
  setCurrentPage: 1,
  jumpFirstOrLast: true,
  firstLabel: 'First Page',
  lastLabel: 'Last Page',
  nextLabel: 'next',
  prevLabel: 'prev',
  rowsPerPageLabel: 'Rows per page',
  ofLabel: 'of',
  pageLabel: 'page', // for 'pages' mode
  allLabel: 'All',
};

const selectOptions = {
  enabled: true,
  selectOnCheckboxOnly: true,
  selectionInfoClass: 'custom-class',
  selectionText: 'rows selected',
  clearSelectionText: 'clear',
  disableSelectInfo: true,
  selectAllByGroup: true,
};

const sortOptions = (store, tableName: string) => ({
  enabled: true,
  initialSortBy: store.state.sortState.table[tableName] || [{ field: 'dateTimePay', type: 'desc' }],
  multipleColumns: true,
});

export default { paginationOptions, selectOptions, sortOptions };
