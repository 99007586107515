import { MutationTree } from 'vuex';

import { IEnumListState } from '@/store/interfaces';

export const mutations: MutationTree<IEnumListState> = {
  SET_BANKS_LIST(state, banksList) {
    state.banksList = banksList;
  },
  SET_CURRENCY_LIST(state, currencyList) {
    state.currencyList = currencyList;
  },
  SET_PERIODIC_LIST(state, periodicList) {
    state.periodicList = periodicList;
  },
};
