import { BaseService } from './base.service';
import { ResponseWrapper, ErrorWrapper } from './util';
import $store from '../store';

export class ImportFileService extends BaseService {
  static async importFile(request) {
    try {
      const response = await this.request({ auth: true }).post('import-file/import-file', request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const data = response.data;

      const importStatus = {
        all: +data.data.imported + data.data.ignored.length + data.errors.length,
        imported: data.data.imported,
        ignored: data.data.ignored.length,
        errored: data.errors.length,
      };
      $store.dispatch('balanceList/setCompanyBalance');
      return new ResponseWrapper(response, { importStatus, fileAnswer: data });
    } catch (error) {
      console.log('response', error);

      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
