<template>
  <div>
    <vue-confirm-dialog></vue-confirm-dialog>
    <dataset v-slot="{ ds }" :ds-data="contragents" :ds-sortby="[sortName]" :ds-search-in="['name', 'inn']">
      <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
        <div class="row col-md-6 mb-2 mb-md-0">
          <dataset-show :ds-show-entries="50" />
          <dataset-info />
        </div>
        <dataset-search ds-search-placeholder="Search..." class="mb-2" />
        <dataset-pager class="mb-2" />
        <div class="mb-2" :data-page-count="ds.dsPagecount"></div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <dataset-item class="form-row mb-3" style="overflow-y: auto; max-height: 600px">
            <template #default="{ row }">
              <div class="col-md-4">
                <div class="card mb-2">
                  <div class="card-body pt-3 pb-2 px-3">
                    <h5 class="card-title mb-2">
                      {{ row.name }}
                    </h5>
                    <h6 class="card-subtitle text-truncate text-muted">{{ row.inn }}</h6>
                    <p class="card-text text-truncate mb-0">{{ row.description }}</p>
                    <p class="card-text text-truncate text-right">
                      <b-button class="btn btn-warning mr-1" @click="EditContragent(row)">
                        <em class="mdi mdi-pen"></em>
                      </b-button>
                      <b-button class="btn btn-danger" @click="deleteContragent(row)">
                        <em class="mdi mdi-minus"></em>
                      </b-button>
                    </p>
                  </div>
                </div>
              </div>
            </template>
            <template #noDataFound>
              <div class="col-md-12 pt-2">
                <p class="text-center">No results found</p>
              </div>
            </template>
          </dataset-item>
        </div>
      </div>
      <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
        <dataset-info class="mb-2 mb-md-0" />
        <dataset-pager />
      </div>
    </dataset>
  </div>
</template>

<script>
export default {
  name: 'ContragentsTable',
  props: ['contragents'],
  data: () => ({
    sortAsc: true,
  }),
  computed: {
    sortName() {
      return this.sortAsc ? 'name' : '-name';
    },
  },
  methods: {
    deleteContragent(item) {
      this.$confirm({
        message: 'Are you sure delete Contragent?',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async (confirm) => {
          if (confirm) {
            this.$emit('deleteContragent', [item.id]);
          }
        },
      });
    },
  },
};
</script>
<style scoped>
.btn-group .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 10;
}
</style>
