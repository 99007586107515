import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';
import $store from '../store';

export class UsersService extends BaseService {
  static async getCurrent() {
    try {
      const response = await this.request({ auth: true }).get('users/current-userinfo');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getUsersByCompany() {
    try {
      const response = await this.request({ auth: true }).get('users/company-users');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async addUser(src_data) {
    try {
      const response = await this.request({ auth: true }).post('users', src_data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async editUser(id, src_data) {
    try {
      const response = await this.request({ auth: true }).put(`users/${id}`, src_data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteUser(id) {
    try {
      const response = await this.request({ auth: true }).delete(`users/${id}`);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async changeActiveCompany(newCompanyId) {
    try {
      const response = await this.request({ auth: true }).put(`users/change-company`, { newCompanyId });
      const { id, name } = this.getCompany(response.data);
      $store.commit('userState/SET_CURRENT_COMPANY', name);
      $store.commit('userState/SET_CURRENT_COMPANY_ID', id);

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async leaveCompany(companyId) {
    try {
      const response = await this.request({ auth: true }).post(`users/leave-company`, { companyId });

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static getCompany(data) {
    const activeId = data.activeCompany;
    const activeCmp = data.companies?.find((cmp) => cmp.id === activeId);

    return { id: activeId, name: activeCmp?.name };
  }

  static async inviteUser(inviteUserDto) {
    try {
      inviteUserDto.inviteDate = new Date();
      const response = await this.request({ auth: true }).post(`invite/invite-user`, { inviteUserDto });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
