import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { IEnumListState } from '../../interfaces';

export const getters: GetterTree<IEnumListState, RootState> = {
  banksList(state) {
    return state.banksList;
  },
  currencyList(state) {
    return state.currencyList;
  },
  periodicList(state) {
    return state.periodicList;
  },
};
