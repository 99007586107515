
import { Component, Vue, PropSync, Watch, Ref } from 'vue-property-decorator';
import { TreeNode } from 'element-ui/types/tree';

import { ArticleDto } from '@/dto/Article.dto';
import { ArticleService } from '@/services/article.service';
import { TreeDefaultProps } from '@/dto/base.dto';

@Component
export default class ArticleForm extends Vue {
  @PropSync('articleList', { type: Array, default: [] }) filteredArticles!: ArticleDto[];

  defaultProps: TreeDefaultProps = {
    children: 'children',
    label: 'name',
  };
  filterText = '';

  @Ref('tree') readonly tree!: Element;

  @Watch('filterText')
  onFilterTextChanged(val: string) {
    (this.$refs.tree as Vue & { filter: (val: string) => void }).filter(val);
  }

  filterNode(value: string, data: ArticleDto) {
    if (!value) return true;
    return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }

  async remove(node: TreeNode<number, ArticleDto>, data: ArticleDto): Promise<void> {
    const id = data.id;
    const answer = await ArticleService.delete([id]);

    if (answer.data.errors === null) {
      //todo show errors
      console.log(answer.data.errors);
    }

    if (answer.data.deleted) {
      const children = node?.parent?.childNodes;
      if (children !== undefined) {
        const index = children.findIndex((d) => d.data.id === data.id);
        children.splice(index, 1);
      }
    }
  }

  async handleDrop(draggingNode, dropNode, dropType: string) {
    if (dropType !== 'none') {
      let dest = null;
      if (dropType === 'inner') {
        dest = dropNode.data.id;
      } else if (dropNode.parent.id !== 0) {
        dest = dropNode.parent.data.id;
      }

      const src = draggingNode.data.id;
      await ArticleService.move(src, dest);
    }
  }

  async append() {
    const answer = await ArticleService.insert({ name: this.filterText });
    this.filteredArticles = answer.data;
    this.filterText = '';
  }
}
