import $store from '../store';
import { AuthService } from '@/services/auth.service';
// import { userHasPermissions } from './util'

/**
 * Current user state initialization
 * @WARN Must be always first in middleware chain
 */
export async function initCurrentUserStateMiddleware(to, from, next) {
  const currentUserRole = $store.state.userState.currentUserRole;
  // const currentUserCompany = $store.state.user.currentCompany;

  if (AuthService.hasRefreshToken() && !currentUserRole) {
    try {
      await AuthService.debounceRefreshTokens();
      await $store.dispatch('userState/setCurrentRole');
      // await $store.dispatch('user/getCurrentCompany')
      next();
    } catch (e) {
      console.log('init user state', e.message);
    }
  } else {
    next();
  }
}

/**
 * Check access permission to auth routes
 */

export function checkAccessMiddleware(to, from, next) {
  const isAuthRoute = to.matched.some((item) => item.meta.isAuth);
  const currentUserRole = $store.state.userState.currentUserRole;
  if (isAuthRoute) {
    if (currentUserRole) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
}

export function setPageTitleMiddleware(to, from, next) {
  const pageTitle = to.meta?.title ? to : to.matched.find((item) => item.meta.title);
  if (pageTitle) window.document.title = pageTitle.meta.title;
  next();
}
