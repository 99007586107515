import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import App from './App.vue';

import './mixins';
import './plugins';
import './plugins/base';

import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ua';

import i18n from './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueConfirmDialog from 'vue-confirm-dialog/src';
import Alert from '@/components/Alerts/alert.vue';

import 'element-ui/lib/theme-chalk/index.css';
import VSwitch from 'v-switch-case';

//table
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

//Dataset
import { Dataset, DatasetItem, DatasetInfo, DatasetPager, DatasetSearch, DatasetShow } from 'vue-dataset';

// import i18n from '@/i18n'
import { AuthService } from '@/services/auth.service';

Vue.use(ElementUI, { locale });

Vue.component('Alert', Alert);

Vue.component('VueConfirmDialog', VueConfirmDialog.default);
Vue.use(VueConfirmDialog);

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(VSwitch);

Vue.use(VueGoodTablePlugin);

//Vue Dataset
Vue.component('Dataset', Dataset);
Vue.component('DatasetItem', DatasetItem);
Vue.component('DatasetInfo', DatasetInfo);
Vue.component('DatasetPager', DatasetPager);
Vue.component('DatasetSearch', DatasetSearch);
Vue.component('DatasetShow', DatasetShow);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  mounted() {
    if (!localStorage.getItem('refreshToken')) AuthService.makeLogout();
    else {
      if (localStorage.locale) {
        this.$vuetify.lang.current = localStorage.locale;
        this.$root.$i18n.locale = localStorage.locale;
        this.$store.commit('localeState/SET_LOCALE', localStorage.locale);
      }

      if (localStorage.tableSort) {
        this.$store.commit('sortState/SET_ALL_SORTS', JSON.parse(localStorage.tableSort));
      }
      if (localStorage.navigationState) {
        this.$store.commit('navigationState/SET_ALL_NAVIGATIONS', JSON.parse(localStorage.navigationState));
      }
      this.$store.dispatch('balanceState/setCompanyBalance');
    }
  },
  render: (h) => h(App),
}).$mount('#app');
//Todo: fill company state in store
