import { BaseService } from './base.service';
import { ResponseWrapper, ErrorWrapper } from './util';
import axios from 'axios';

export class CommonService extends BaseService {
  static async getBanksList(): ResponseWrapper {
    try {
      const response = await this.request({ auth: false }).get('bank-list');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getCurrencyList(): ResponseWrapper {
    try {
      const response = await this.request({ auth: false }).get('currency-list');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getPeriodicList(): ResponseWrapper {
    try {
      const response = await this.request({ auth: false }).get('periodic-list');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }
}
