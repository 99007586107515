import { ActionTree } from 'vuex';

import { IToastState } from '@/store/interfaces';
import { RootState } from '@/store/types';

export const actions: ActionTree<IToastState, RootState> = {
  setErrorMessage({ commit }, message: string): void {
    commit('NEW', { message, type: 'error' });
  },
};
