
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { State } from 'vuex-class';

import DividedPayForm from './DividedPayForm.vue';
import { PaysService } from '@/services/pay.service';
import { TLists, TTreeStruct } from '@/dto/base.dto';

import { CardDto } from '@/dto/Card.dto';
import { TNewPay, TDividePay, TPeriodicSettings } from '@/dto/Pays.dto';
import { CurrencyCode, PayType, PayTypeSource } from '@/dto/enums';

@Component({
  components: { Treeselect, DividedPayForm },
})
export default class ModalEditPay extends Vue {
  @PropSync('editRow') row!: TNewPay;
  @Prop() asInSource!: string[];
  @Prop() action!: string;
  @Prop() ppt!: PayType;

  @State('banksList', { namespace: 'enumState' }) banksList!: string[];
  @State('currencyList', { namespace: 'enumState' }) currList!: string[];
  @State('periodicList', { namespace: 'enumState' }) periodList!: string[];

  @State('articleList', { namespace: 'articleList' }) articleList!: TTreeStruct[];
  @State('cardList', { namespace: 'cardList' }) cardList!: TTreeStruct[];
  @State('contragentList', { namespace: 'contragentList' }) contragentList!: TTreeStruct[];
  @State('projectList', { namespace: 'projectList' }) projectList!: TTreeStruct[];

  balanceRow = 0;
  toFuture = false;
  periodicSettings: TPeriodicSettings = { period: null, countPeriod: 0 };

  localRow: TNewPay = {};
  defaults: TNewPay = {};
  editedFields: string[] = [];

  parentPayType: PayType = this.row?.payType || PayType.PAY_INCOME;

  get currencyList() {
    return this.mapEnums(this.currList);
  }

  get periodicList() {
    return this.mapEnums(this.periodList);
  }

  get isApproveVisible() {
    return this.localRow.typeSource == PayTypeSource.MANUAL;
  }

  get dividePayRows(): TDividePay[] {
    return this.localRow?.divided ?? [];
  }

  get localLists(): TLists {
    const lists = {
      bankList: this.mapEnums(this.banksList),
      currencyList: this.mapEnums(this.currencyList),
      periodicList: this.mapEnums(this.periodicList),
      cardList: this.cardList,
      contragentList: this.contragentList,
      projectList: this.projectList,
      articleList: this.articleList,
    };
    if (this.isMultipleEdit()) {
      for (const key of Object.keys(lists)) {
        lists[key].push({ id: -1, name: 'As in Source' });
      }
    }
    return lists;
  }

  async getRow(): Promise<TNewPay> {
    const row: TNewPay = JSON.parse(JSON.stringify(this.row));

    if (this.isMultipleEdit()) {
      for (const key of this.asInSource) {
        switch (key) {
          case 'dateTimeAccrual':
          case 'dateTimeOperation':
            row[key] = '';
            break;
          case 'customDescription':
            row[key] = '';
            break;
          default:
            row[key] = -1;
            break;
        }
      }
    }

    return row;
  }

  getInputClass(fieldName) {
    return this.isFieldEdited(fieldName) ? 'edited' : '';
  }

  isFieldEdited(fieldName) {
    if (!this.isMultipleEdit()) return false;
    return this.editedFields.indexOf(fieldName) !== -1;
  }

  @Watch('row')
  async onPropRowChange() {
    this.localRow = await this.getRow();
    this.defaults = await this.getRow();
    this.editedFields = [];
  }

  @Watch('localRow.card')
  async onCardChange() {
    if (this.localRow.card) {
      const card = this.cardList.find((elem) => elem.id === this.localRow.card);
      if (card !== undefined && (card as unknown as CardDto).currency !== undefined) {
        const curr = (card as unknown as CardDto).currency;
        this.localRow.currency = +CurrencyCode[curr];
      }
    }
  }

  returnToDefaults() {
    this.localRow = JSON.parse(JSON.stringify(this.defaults));
    this.editedFields = [];
  }

  onFieldChange(fieldName: string, node?: TTreeStruct) {
    if (node?.id === -1) {
      this.editedFields.splice(this.editedFields.indexOf(fieldName), 1);
      return;
    }
    if (this.editedFields.includes(fieldName)) return;
    this.editedFields.push(fieldName);
  }

  checkEditable() {
    return this.localRow.typeSource == PayTypeSource.MANUAL;
  }

  handleOk() {
    if (this.action === 'add' && this.periodicSettings.period && this.periodicSettings.countPeriod > 0) {
      this.localRow.periodicSettings = this.periodicSettings;
    }
    this.$emit('editPay', this.localRow, this.action, this.editedFields);
    this.$nextTick(() => {
      this.$bvModal.hide('modal-edit-pay');
    });
  }

  handleDelete() {
    this.$emit('deletePay', [this.localRow.id], () => {
      this.$bvModal.hide('modal-edit-pay');
    });
  }

  addDividePayRow() {
    const newDividedPay: TDividePay = {
      sumOplata: this.balanceRow,
      project: this.localRow?.project?.id ?? null,
      article: this.localRow.article?.id ?? null,
      dateTimeAccrual: (this.localRow.dateTimeAccrual ?? new Date()) as Date,
      isApproved: false,
    };
    this.localRow?.divided?.push(newDividedPay);
  }

  // @Watch('row.sumOplata')
  @Watch('localRow.sumOplata')
  // @Watch('row.children')
  onSumOplataChange() {
    console.log('change localRow.sumOplata');
    this.checkBalance();
  }

  isMultipleEdit() {
    return this.action == 'multiple-edit';
  }

  checkBalance() {
    const fullSum = this.localRow?.sumOplata || 0;
    const dividedPays = this.dividePayRows.reduce((sum, elem) => {
      return sum + +elem.sumOplata;
    }, 0);
    this.balanceRow = fullSum - dividedPays;
    console.log('in checkBalance', fullSum, '-', dividedPays, '=', this.balanceRow);
  }

  async deleteDividePay(id: number, dividedPay) {
    if (dividedPay.id) {
      await PaysService.delete([dividedPay.id]);
    }
    this.dividePayRows.splice(id, 1);
  }
}
