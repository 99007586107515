
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { PaysService } from '@/services/pay.service';
import PaysTable from '@/components/Pays/PaysTable.vue';
import { PaysTableDto, TCurrencyBalance } from '@/dto/Pays.dto';

@Component({
  components: { PaysTable },
})
export default class PaysPage extends Vue {
  pays: PaysTableDto[] = [];
  balance: TCurrencyBalance = {};

  @State((state) => state.pays, { namespace: 'filterList' }) payFilter;

  mounted() {
    this.getFilteredPays();
    const unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'filterList/SET_FILTER') {
        this.getFilteredPays();
      }
    });
  }

  async getFilteredPays() {
    const filter = { ...this.payFilter };
    const answer = (await PaysService.getFilteredPays(filter)).data as PaysTableDto[];
    this.pays = answer;
    this.balance = {};
  }
}
