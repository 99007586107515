<template>
  <div>
    <div @mouseover="hover = true" @mouseleave="hover = false">
      <admin-main-panel />
    </div>
    <div class="d-flex flex-row">
      <div class="middle-pannel" @mouseover="hover = true" @mouseleave="hover = false">
        <v-btn
          class="toggle-menu-button to-close"
          fab
          dark
          x-small
          :hidden="!(hover && !mini)"
          @click.stop="toggleMini(true)"
        >
          <v-icon dark>mdi-minus</v-icon>
        </v-btn>
        <v-btn class="toggle-menu-button to-open" fab dark x-small :hidden="!mini" @click.stop="toggleMini(false)">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </div>
      <admin-core-view class="max-heigth" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminIndex',
  components: {
    // DashboardCoreAppBar: () => import('./components/core/AppBar'),
    AdminMainPanel: () => import('./core/MainPanel'),
    // DashboardCoreSettings: () => import('./components/core/Settings'),
    AdminCoreView: () => import('./core/View'),
  },

  data: () => ({
    hover: false,
  }),

  computed: {
    mini() {
      return this.$store.state.navigationState.mini;
    },
    drawer() {
      return this.$store.state.navigationState.drawer;
    },
  },

  methods: {
    toggleMini(newState) {
      this.$store.commit('navigationState/SET_MINI_STATE', newState);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-menu-button {
  position: absolute;
  top: 22px;
  left: -17px;
  z-index: 200;

  transition: 2s;

  &.to-open {
    left: -5px;
  }

  @media (max-width: 960px) {
    display: none;
  }
}

.max-heigth {
  height: 90vh;
  overflow-y: scroll;
}
</style>
