
import { Vue, Component } from 'vue-property-decorator';
type AlertType = 'success' | 'error';
type AlertObject = { text: string; type: AlertType };

@Component
export default class Alert extends Vue {
  snackbar = true;
  alerts: AlertObject[] = [];

  error(text: string) {
    this.createAlert({ text, type: 'error' });
  }

  success(text: string) {
    this.createAlert({ text, type: 'success' });
  }

  reset() {
    this.alerts = [];
    this.snackbar = false;
  }
  getColor(type: AlertType) {
    switch (type) {
      case 'error':
        return 'red';
      case 'success':
        return 'green';
    }
  }

  getStyle(index: number) {
    return `margin-top: ${index * 70}px`;
  }

  removeElement(index: number) {
    this.alerts.splice(index, 1);
  }

  createAlert(alert: AlertObject) {
    this.alerts.push(alert);
    this.snackbar = true;
    setTimeout(() => {
      const index = this.alerts.indexOf(alert);
      this.alerts.splice(index, 1);
    }, 5000);
  }
}
