import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class InviteService extends BaseService {
  static async inviteUser(inviteUserDto) {
    try {
      inviteUserDto.inviteDate = new Date();
      const response = await this.request({ auth: true }).post(`invite/invite-user`, { inviteUserDto });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getMyInvitations() {
    try {
      const response = await this.request({ auth: true }).get(`invite/my-invitation`);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getSendedInvitations() {
    try {
      const response = await this.request({ auth: true }).get(`invite/sended-invitation`);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async postAcceptInvite(token) {
    try {
      const response = await this.request({ auth: true }).post(`invite/accept-invite`, { token });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async postDeclineInvite(token) {
    try {
      const response = await this.request({ auth: true }).post(`invite/decline-invite`, { token });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async postDropInvite(token) {
    try {
      const response = await this.request({ auth: true }).post(`invite/drop-invite`, { token });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
