import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { IProjectListState } from '@/store/interfaces';
import { ProjectDto } from '@/dto/Projects.dto';

export const actions: ActionTree<IProjectListState, RootState> = {
  setProjectList({ commit }, projects: ProjectDto[]): void {
    commit('SET_PROJECT_LIST', projects);
  },
};
