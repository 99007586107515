import { MutationTree } from 'vuex';
import { IDashboardState } from '../../interfaces';

export const mutations: MutationTree<IDashboardState> = {
  SET_BAR_IMAGE(state, payload) {
    state.barImage = payload;
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload;
  },
};
