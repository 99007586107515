import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { ICardListState } from '@/store/interfaces';
import { CardDto } from '@/dto/Card.dto';

export const getters: GetterTree<ICardListState, RootState> = {
  getCardList(state): CardDto[] {
    return state.cardList || [];
  },
};
