import { BaseService } from './base.service';
import { ResponseWrapper, ErrorWrapper } from './util';
import { TPay, PaysTableDto, TNewPay } from '../dto/Pays.dto';
import { FilterStruct } from '@/dto/base.dto';
import { CardDto } from '../dto/Card.dto';
import axios from 'axios';
import $store from '../store';

export class PaysService extends BaseService {
  static async getFilteredPays(filter: FilterStruct) {
    try {
      const response = await this.request({ auth: true }).post('pays', filter);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      console.log(error);
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getTransferById(id: number) {
    try {
      const response = await this.request({ auth: true }).get(`pays/transfer-by-id/${id}`);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async createPay(newPay: TPay) {
    try {
      const response = await this.request({ auth: true }).post('pays/create', newPay);
      $store.dispatch('balanceState/setCompanyBalance');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updatePay(pay: TPay | TNewPay) {
    try {
      const response = await this.request({ auth: true }).put('pays/update', pay);
      $store.dispatch('balanceState/setCompanyBalance');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updatePayField(updateVals: unknown) {
    try {
      const response = await this.request({ auth: true }).put('pays/update-field', updateVals);
      $store.dispatch('balanceState/setCompanyBalance');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async delete(ids: number[]) {
    try {
      const response = await this.request({ auth: true }).delete(`pays`, { data: { ids } });
      $store.dispatch('balanceState/setCompanyBalance');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async approve(ids: number[]) {
    try {
      const response = await this.request({ auth: true }).put('pays/approve-pays', { ids });
      $store.dispatch('balanceState/setCompanyBalance');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static checkMayTransfer(transfer1: PaysTableDto, transfer2: PaysTableDto) {
    if (
      !transfer1 ||
      !transfer2 ||
      transfer1.transferId ||
      transfer2.transferId ||
      (transfer1 &&
        transfer2 &&
        ((transfer1.currency === transfer2.currency && transfer1.sumOplata !== -1 * transfer2.sumOplata) ||
          (transfer1.card as CardDto).id === (transfer2.card as CardDto).id))
    )
      return false;

    return true;
  }

  static async updateTransfer(ids: number[], isCreateTransfer: boolean) {
    try {
      const response = await this.request({ auth: true }).post('pays/transfer-pay', { ids, isCreateTransfer });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async createTransfer(transfers: unknown) {
    try {
      const response = await this.request({ auth: true }).post('pays/create-transfer', { transfers });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }
}
