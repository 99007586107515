<template>
  <div class="card-container">
    <b-card :title="title" class="mb-5" :bg-variant="status">
      <b-card-text>
        <p v-for="(item, id) in datas" :key="`item_${id}`">{{ item.line || '' }} : {{ item.message }}</p>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'ImportMessageCard',
  props: ['datas', 'title', 'status'],
};
</script>
<style>
.card-container {
  max-width: 30%;
}
</style>
