import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { IEnumListState } from '../../interfaces';
import { CommonService } from '@/services/common.service';

export const actions: ActionTree<IEnumListState, RootState> = {
  async setBanksList({ commit }) {
    CommonService.getBanksList()
      .then((banksList) => {
        commit('SET_BANKS_LIST', banksList.data);
      })
      .catch((error) => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
  },
  async setCurrencyList({ commit }) {
    CommonService.getCurrencyList()
      .then((currencyList) => {
        const currency = {};
        for (const val in currencyList.data) {
          if (!isNaN(+val)) {
            currency[val] = currencyList.data[val];
          }
        }
        commit('SET_CURRENCY_LIST', currency);
      })
      .catch((error) => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
  },
  async setPeriodicList({ commit }) {
    CommonService.getPeriodicList()
      .then((periodicList) => {
        commit('SET_PERIODIC_LIST', periodicList.data);
      })
      .catch((error) => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
  },
};
