
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { TTransfer } from '@/dto/Pays.dto';
import { TTreeStruct } from '@/dto/base.dto';
import { PaysService } from '@/services/pay.service';
import { PayType } from '@/dto/enums';

@Component({
  components: { Treeselect },
})
export default class ModalTransferPay extends Vue {
  @PropSync('selectedRows') selectedR!: number[];

  @State('banksList', { namespace: 'enumState' }) banksList!: string[];
  @State('currencyList', { namespace: 'enumState' }) currList!: string[];
  @State('periodicList', { namespace: 'enumState' }) periodList!: string[];

  @State('articleList', { namespace: 'articleList' }) articleList!: TTreeStruct[];
  @State('cardList', { namespace: 'cardList' }) cardList!: TTreeStruct[];
  @State('contragentList', { namespace: 'contragentList' }) contragentList!: TTreeStruct[];
  @State('projectList', { namespace: 'projectList' }) projectList!: TTreeStruct[];

  deaultTransfer: TTransfer = {
    dateTimeOperation: new Date(),
    sumOplata: 0,
    isApproved: false,
    payType: PayType.PAY_TRANSFER,
  };
  transferIn = {} as TTransfer;
  transferOut = {} as TTransfer;

  filledIn = false;
  filledOut = false;

  clearTransfers() {
    this.transferIn = { ...this.deaultTransfer };
    this.transferOut = { ...this.deaultTransfer };
    this.filledIn = false;
    this.filledOut = false;
  }

  @Watch('selectedRows')
  async onSelectedRowsChange() {
    this.clearTransfers();
    if (this.selectedR.length === 1) {
      const selected = await this.fillTransfer(this.selectedR[0]);
      this.setTransfer(selected);

      if (selected.transferId) {
        const selected2 = await this.fillTransfer(selected.transferId);
        this.setTransfer(selected2);
      }
    }

    if (this.selectedR.length === 2) {
      const selected = await this.fillTransfer(this.selectedR[0]);
      const selected2 = await this.fillTransfer(this.selectedR[1]);
      if (selected.payType != selected2.payType && selected.sumOplata === selected2.sumOplata) {
        this.setTransfer(selected);
        this.setTransfer(selected2);
      }
    }
  }

  setTransfer(transfer: TTransfer) {
    if (transfer.payType === PayType.PAY_INCOME) {
      this.transferIn = transfer;
      this.filledIn = true;
    } else {
      this.transferOut = transfer;
      this.filledOut = true;
    }
  }

  async fillTransfer(id: number): Promise<TTransfer> {
    const selected = (await PaysService.getTransferById(id)).data;
    return {
      id: selected.id || null,
      card: selected.card?.id || null,
      article: selected.article?.id || null,
      project: selected.project?.id || null,
      dateTimeOperation: selected.dateTimeOperation || new Date(),
      isApproved: selected.isApproved || false,
      sumOplata: Math.abs(selected.sumOplata) || 0,
      payType: selected.payType,
      transferId: selected.transferId,
      customDescription: selected.customDescription ?? '',
    };
  }

  handleOk() {
    this.$emit('createTransfer', { transferIn: this.transferIn, transferOut: this.transferOut });
    this.$nextTick(() => {
      this.$bvModal.hide('modal-transfer-pay');
    });
  }
  handleUnTransfer() {
    this.$emit('unTransfer', false);
    this.$nextTick(() => {
      this.$bvModal.hide('modal-transfer-pay');
    });
  }

  checkDisabled(source): boolean {
    if (this.filledIn && this.filledOut) return true;
    if ((this.filledIn && source == 'to') || (this.filledOut && source == 'from')) return true;
    return false;
  }
}
