<template>
  <div>
    <h4>Settings mono edit</h4>
    <!-- <div class="form-line">
      <b-form-input type="password" v-model="monoToken">{{ monoToken }}</b-form-input>
      <b-button class="btn btn-info" @click="syncMonoCards">Sync</b-button>
    </div>
    <h5 v-if="cards.owner">Owner: {{ cards.owner }}</h5>
    <b-table v-model="cards.cards" name="cards-table" :items="cards.cards" :fields="sortFields">
      <template #cell(selected)="data">
        <b-form-checkbox v-model="selectedCards" :value="data.item" @change="changeCheckbox"></b-form-checkbox>
      </template>
      <template #cell(currency)="data">
        {{ currencyByCode(data.item.currencyCode) }}
      </template>
      <template #cell(balance)="data">
        {{ currencyFormat(data.item.balance / 100, data.item.currencyCode) }}
      </template>
    </b-table> -->
  </div>
</template>

<script>
import { CardService } from '@/services/card.service';

export default {
  name: 'MonoEditForm',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data: () => ({
    monoToken: '',
    cards: {
      owner: '',
      cards: [],
    },
    selectedCards: [],
    sortFields: [
      { key: 'selected', label: '' },
      { key: 'maskedPan[0]', label: 'Card' },
      { key: 'iban' },
      { key: 'currency' },
      { key: 'balance' },
    ],
  }),
  methods: {
    async syncMonoCards() {
      const { data } = await CardService.syncMonoCards(this.monoToken);
      this.cards = { owner: data.name, cards: data.accounts.sort((a, b) => (a.iban > b.iban ? 1 : -1)) };
    },
    changeCheckbox() {
      this.$emit('update:modelValue', { token: this.monoToken, cards: this.selectedCards });
    },
  },
};
</script>
<style scoped>
.form-line {
  display: flex;
}
</style>
