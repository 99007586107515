
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WebHookService } from '@/services/webHook.service';
import Alert from '@/components/Alerts/alert.vue';

@Component
export default class WebHookSettings extends Vue {
  @Prop() alert!: Alert;
  apiKey = '';
  dests = {
    mono: 'Mono bank',
    finmap: 'FinMap',
  };
  destination = 'mono';
  hookAnswer = '';

  async checkHooks() {
    try {
      this.hookAnswer = (await WebHookService.checkHooks({ apiKey: this.apiKey, dest: this.destination })).data;
    } catch (error) {
      this.alert.error(this.formatErrorMessage(error));
    }
  }

  async setHook() {
    try {
      this.hookAnswer = (await WebHookService.setHook({ apiKey: this.apiKey, dest: this.destination })).data;
    } catch (error) {
      this.alert.error(this.formatErrorMessage(error));
    }
  }

  async deleteHook(param) {
    return await WebHookService.deleteHook({ ...param, dest: this.destination });
  }
}
