
import { Component, PropSync, Vue } from 'vue-property-decorator';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { TSelectStruct } from '@/dto/base.dto';

@Component({
  components: { Treeselect },
})
export default class ModalList extends Vue {
  @PropSync('modalListTree') listTree!: TSelectStruct[];
  @PropSync('editField') field!: string;
  @PropSync('currentChoice', { default: '' }) current!: string;

  id = null;

  handleOk() {
    this.$emit('updatePayField', this.field, this.id);
    this.$nextTick(() => {
      this.id = null;
      this.$bvModal.hide('modal-list-edit');
    });
  }

  hidden() {
    this.$emit('close');
  }
}
