<template>
  <div>
    <b-table
      responsive
      striped
      hover
      sort-icon-left
      name="cards-table"
      :items="filteredCards"
      :fields="sortFields"
      @row-clicked="onRowClicked"
    >
      <template #cell(updatedAt)="data">
        {{ localeDate(data.item.updatedAt) }}
      </template>

      <template #cell(actions)="row">
        <b-button class="mr-3" variant="info" @click="detail(row.item)">Detail</b-button>
        <b-button v-if="row.item.syncSettings" class="mr-3" variant="info" @click="$emit('getPaysFormShow', row.item)">
          Get pays
        </b-button>
        <b-button class="mr-3" variant="warning" @click="$emit('fillModal', row.item, 'edit')">Edit</b-button>
        <b-button class="mr-3" variant="danger" @click="$emit('deleteCard', [row.item])">Delete</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'CardsTable',
  props: ['cards'],
  data: () => ({
    sortFields: [
      { key: 'bank', sortable: true },
      { key: 'account.name', sortable: true },
      { key: 'name', sortable: true },
      { key: 'currency', sortable: true },
      { key: 'iban', sortable: true },
      { key: 'initialBalance', sortable: true },
      { key: 'balance', sortable: true },
      { key: 'updatedAt', sortable: true },
      { key: 'description', sortable: false },
      { key: 'actions', sortable: false },
    ],
    sortBy: 'name',
    sortDesc: false,
    perPage: 10,
    currentPage: 1,
    rows: 0,
    isBusy: false,
    visibleRows: [],
    selectedCard: null,
  }),
  computed: {
    filteredCards() {
      return this.cards;
    },
  },
  methods: {
    onRowClicked(item) {
      this.selectedCard = item;
      this.$emit('rowClick', this.selectedCard.id);
    },
    detail(card) {
      // console.log('detail', card);
    },
  },
};
</script>

<style></style>
