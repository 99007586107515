<template>
  <div>
    Cards
    <!-- v-model="visibleRows" -->
    <b-table responsive striped hover sort-icon-left name="cards-table" :items="filteredCards" :fields="sortFields">
      <!-- <template #cell(actions)="row"> -->
      <!-- <b-button class="mr-3" variant="info" @click="deleteAccountCard(row.item)"> Detail </b-button> -->
      <!-- <b-button class="mr-3" variant="warning" @click="$emit('fillModal', row.item, 'edit')"> Edit </b-button>
        <b-button class="mr-3" variant="danger" @click="deleteAccount(row.item)"> Delete </b-button> -->
      <!-- </template> -->
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'CardsTable',
  props: ['accountId', 'cards'],
  data: () => ({
    sortFields: [
      { key: 'name', sortable: true },
      { key: 'currency', sortable: true },
      { key: 'iban', sortable: true },
      { key: 'initialBalance', sortable: true },
      // { key: 'updatedAt', sortable: true },
      { key: 'description', sortable: false },
      // { key: 'actions', sortable: false },
    ],
    sortBy: 'name',
    sortDesc: false,
    perPage: 10,
    currentPage: 1,
    rows: 0,
    isBusy: false,
    visibleRows: [],
  }),
  computed: {
    filteredCards() {
      return this.cards;
    },
  },
};
</script>

<style></style>
