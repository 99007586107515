import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';
import { IContragentListState } from '../../interfaces';

export const state: IContragentListState = {
  contragentList: [],
};

const namespaced = true;

export const contragentList: Module<IContragentListState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
