import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { RootState } from '@/store/types';
import { INavigationState } from '@/store/interfaces';

export const state: INavigationState = {
  drawer: true,
  mini: false,
  panel: 'cards',
};

const namespaced = true;

export const navigationState: Module<INavigationState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
