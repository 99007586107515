import { BaseService } from './base.service';
import $store from '../store';
import { ResponseWrapper, ErrorWrapper } from './util';
import axios from 'axios';
import { CardDto } from '../dto/Card.dto';

export class CardService extends BaseService {
  static async getAllCards(): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).get('cards');
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getCardsList(): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).get('cards/list');
      $store.dispatch('cardList/setCardList', response.data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async syncMonoCards(token: string): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).post('cards/mono-cards-by-token', { token });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async edit(action: string, data: CardDto): ResponseWrapper {
    try {
      console.log('card add', data);
      const response = await this.request({ auth: true }).post(`cards/${action}`, data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getPays(data): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).post(`cards/get-pays`, {
        ...data,
        company: $store.getters['userState/currentCompanyId'],
      });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async delete(ids: number[]): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).delete(`cards`, { data: { ids } });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }
}
