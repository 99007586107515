
import { Component, Vue, Prop } from 'vue-property-decorator';

import BalanceContainer from '@/components/Balance/BalanceContainer.vue';
import { TCurrencyBalance } from '@/dto/Pays.dto';
import { Getter } from 'vuex-class';

@Component({
  components: { BalanceContainer },
})
export default class BalancePanel extends Vue {
  @Prop({ default: false }) readonly isInline!: boolean;
  @Prop({ default: false }) readonly onlyFullBalance!: boolean;

  @Getter('getBalance', { namespace: 'balanceState' }) getBalance;
  @Getter('getBalanceNoReload', { namespace: 'balanceState' }) getBalanceNoReload;

  get balance(): TCurrencyBalance {
    return this.onlyFullBalance ? this.getBalanceNoReload : this.getBalance;
  }
}
