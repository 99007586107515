
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { TCurrencyBalance } from '@/dto/Pays.dto';

@Component({})
export default class BalanceContainer extends Vue {
  @Prop({ default: false }) readonly isInline!: boolean;
  @PropSync('balance', { type: Object, default: {} }) currentBalance!: TCurrencyBalance;

  get balanceCurrencies(): string[] {
    return Object.keys(this.currentBalance);
  }

  countBalance(balance: TCurrencyBalance[string], currency: string): string {
    return this.formatCurrency(balance.negative + balance.positive, currency);
  }
}
