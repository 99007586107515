
import { Component, Vue } from 'vue-property-decorator';

import ArticleForm from '@/components/Articles/ArticleForm.vue';
import { ArticleService } from '@/services/article.service';
import Alert from '@/components/Alerts/alert.vue';
import { TAlertType } from '@/types/alert.type';

@Component({
  components: { ArticleForm, Alert },
})
export default class ArticlesPage extends Vue {
  articles: unknown[] = [];

  alertMessage: TAlertType = {} as TAlertType;

  async mounted() {
    this.alertMessage = this.$refs.alert as TAlertType;

    this.articles = (await ArticleService.getArticleList()).data;
  }
}
