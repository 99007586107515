<template>
  <div>
    <!-- <ModalEdit
      :form="editProjectData"
      :modalAction="modalAction"
      @editModal="editProject"
    />-->
    <h2>
      Contragents
      <!-- <b-button variant="success" @click="fillProjectForm">+</b-button> -->
    </h2>
    <alert ref="alert" />
    <ContragentsTable :contragents="contragents" @deleteContragent="deleteContragents" />
    <!-- @fillModal="fillProjectForm" -->
  </div>
</template>

<script>
import { ContragentService } from '@/services/contragent.service';
import ContragentsTable from '@/components/Contragents/ContragentsTable.vue';
// import ModalEdit from '@/components/Projects/ModalEdit.vue';

export default {
  name: 'ContragentsPage',
  components: { ContragentsTable }, //ModalEdit
  data: () => ({
    contragents: [],
    // editProjectData: {
    //   id: 0,
    //   name: '',
    //   description: ''
    // },
    // modalAction: 'add',
    alert: {
      message: '',
      status: false,
    },
  }),
  async mounted() {
    await this.getAllContragents();
  },
  methods: {
    async getAllContragents() {
      this.contragents = (await ContragentService.getAllContragents()).data;
    },

    // fillProjectForm(projectData, action = 'add') {
    //   if (action === 'add') {
    //     this.editProjectData = {
    //       name: '',
    //       description: '',
    //     };
    //   } else {
    //     this.editProjectData = { ...projectData };
    //   }
    //   this.modalAction = action;
    //   this.$bvModal.show("modal-project-edit");

    // },

    // async editProject(action, formData) {
    //   try {
    //     const answer = await ProjectService.edit(action, formData);
    //     // this.showModalInfo(answer.statusMessage, 'success')
    //     this.$refs.alert.success(answer.statusMessage);
    //     await this.getAllProjects();
    //   } catch (e) {
    //     console.error(e.response.data.message)
    //     this.showModalInfo(e.response.data.message, 'danger')
    //   }
    // },

    async deleteContragents(ids) {
      try {
        const answer = await ContragentService.delete(ids);
        if (answer.data.errors.leight > 0) {
          const errorMessages = answer.data.errors.map(
            (err) => `Contragent with id ${err.id} not deleted. ${err.message}`,
          );
          this.$refs.alert.danger(errorMessages.join('\n'));
        } else {
          this.$refs.alert.success('Contragent(s) deleted!');
        }
        await this.getAllContragents();
      } catch (e) {
        console.error(e.response.data.message);
        this.showModalInfo(e.response.data.message, 'danger');
      }
    },
  },
};
</script>
