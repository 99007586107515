import Vue from 'vue';
import Vuetify from 'vuetify';
import uk from 'vuetify/src/locale/uk';
import en from 'vuetify/src/locale/en';
import 'vuetify/dist/vuetify.min.css';
// import i18n from '@/i18n'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, uk },
    current: 'en',
  },
  // lang: {
  //   t: (key, ...params) => i18n.t(key, params).toString(),
  // },
});
