
import { Component, Vue } from 'vue-property-decorator';

import { AccountService } from '@/services/account.service';

import AccountsTable from '@/components/Accounts/AccountsTable.vue';
import ModalAccountEdit from '@/components/Accounts/ModalAccountEdit.vue';
import CardsTable from '@/components/Accounts/CardsTable.vue';
import { TAlertType } from '@/types/alert.type';
import { State } from 'vuex-class';
import { ErrorWrapper } from '@/services/util';

@Component({
  components: { AccountsTable, CardsTable, ModalAccountEdit },
})
export default class AccountsPage extends Vue {
  @State('banksList', { namespace: 'enumState' }) bankList!: Record<string, string>;
  alertMessage: TAlertType = {} as TAlertType;

  accounts = [];
  accountCards = [];

  editAccountData = {
    id: null,
    name: '',
    description: '',
    cards: [],
    initialBalance: 0,
  };
  modalAction = 'add';

  async mounted() {
    await this.getAllAccounts();
    this.alertMessage = this.$refs.alert as TAlertType;
  }

  async getAllAccounts() {
    this.accounts = (await AccountService.getAllAccounts()).data;
  }

  async getCards(id) {
    this.accountCards = (await AccountService.getCardsByAccount(id)).data;
  }

  async fillAccountForm(accountData, action = 'add') {
    if (action === 'add') {
      this.editAccountData = {
        id: null,
        name: '',
        description: '',
        initialBalance: 0,
        cards: [],
      };
    } else {
      const cards = accountData.cards.map((card) => card.id);
      this.editAccountData = { ...accountData, cards };
    }
    this.modalAction = action;

    this.$bvModal.show('modal-account-edit');
  }

  async editAccount(action: string, formData) {
    try {
      const answer = await AccountService.edit(action, formData);
      this.showAlert('success', answer.statusMessage);
      await this.getAllAccounts();
    } catch (e) {
      this.showAlert('danger', (e as ErrorWrapper).message);
    }
  }

  showAlert(typeMessage: string, message: string) {
    if (typeMessage == 'success') this.alertMessage.success(message);
    else this.alertMessage.danger(message);
  }

  async deleteAccount(id: number) {
    this.$confirm({
      message: 'Are you sure delete Account?',
      button: {
        no: 'No',
        yes: 'Yes',
      },
      callback: async (confirm) => {
        if (confirm) {
          try {
            const deleted = await AccountService.delete([id]);
            this.showAlert('success', deleted.statusMessage);
            await this.getAllAccounts();
          } catch (e) {
            console.log(e);
          }
        }
      },
    });
  }
}
