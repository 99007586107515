import { BaseService } from './base.service';
import { ResponseWrapper, ErrorWrapper } from './util';
import $store from '../store';
import axios from 'axios';
import { ProjectDto } from '@/dto/Projects.dto';

export class ProjectService extends BaseService {
  static async getProjectList(): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).get('projects');
      $store.dispatch('projectList/setProjectList', response.data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async edit(action: string, data: ProjectDto): ResponseWrapper {
    try {
      if (action === 'add') {
        const response = await this.request({ auth: true }).post(`projects`, data);
        return new ResponseWrapper(response, response.data);
      }
      if (action === 'edit') {
        const response = await this.request({ auth: true }).put(`projects`, data);
        return new ResponseWrapper(response, response.data);
      }
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async insert(data): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).post(`projects`, data);
      $store.commit('projectList/SET_PROJECT_LIST', response.data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async move(src: number, dst: number | null): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).put(`projects/move`, {
        id: src,
        newParent: dst,
      });
      $store.commit('projectList/SET_PROJECT_LIST', response.data);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }

  static async delete(ids: number[]): ResponseWrapper {
    try {
      const response = await this.request({ auth: true }).delete(`projects`, { data: { ids } });
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = axios.isAxiosError(error) ? error.message : error;
      throw new ErrorWrapper(error, message);
    }
  }
}
