<template>
  <b-navbar toggleable="lg" type="dark" variant="info">
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-brand href="#">{{ source }}</b-navbar-brand>
      <b-navbar-nav>
        <b-button variant="warning" @click="$emit('importFiles')">Import</b-button>

        <b-progress
          v-if="importFileStatistic.selected > 0"
          class="ml-5"
          style="width: 200px"
          show-value
          :max="importFileStatistic.selected"
        >
          <b-progress-bar :value="importFileStatistic.imported" variant="success"></b-progress-bar>
          <b-progress-bar :value="importFileStatistic.ignored" variant="warning"></b-progress-bar>
          <b-progress-bar variant="danger" :value="importFileStatistic.errored"></b-progress-bar>
        </b-progress>
        <b-spinner v-if="importFilesStatus" class="ml-5" label="Importing..." variant="warning"></b-spinner>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown text="Rows" right>
          <b-dropdown-item
            v-for="(option, id) in tableRowCount"
            :key="`t-row-count+${id}`"
            :value="option"
            @click="$emit('changeTableRowCount', id)"
          >
            {{ option }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Status" right>
          <b-dropdown-item
            v-for="option in filterStatusOptions"
            :key="option.key"
            :value="option.value"
            @click="
              $emit('applayFilter', {
                filterName: 'status',
                value: option.value,
              })
            "
          >
            {{ option.label }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'TableActionPallet',
  props: ['source', 'importFilesStatus', 'importFileStatistic'], //, 'countImported', 'countErrored', 'countIgnored'
  data() {
    return {
      filterStatusOptions: [
        {
          key: 'status-all',
          label: 'All',
          value: 'all',
        },
        {
          key: 'status-ignored',
          label: 'Ignored',
          value: 'ignored',
        },
        {
          key: 'status-imported',
          label: 'Imported',
          value: 'imported',
        },
        {
          key: 'status-not_imported',
          label: 'Not imported',
          value: 'not_imported',
        },
        {
          key: 'status-errored',
          label: 'Errored',
          value: 'errored',
        },
      ],
      tableRowCount: { all: 'All', 10: 10, 20: 20, 50: 50, 100: 100 },
      clicked: '',
    };
  },
  methods: {},
};
</script>

<style>
.navbar-dark .navbar-nav .nav-link,
.navbar-brand {
  color: black !important;
}
</style>
