
import { Vue, Component, Ref } from 'vue-property-decorator';
import { ErrorWrapper } from '@/services/util';

import { ProjectService } from '@/services/project.service';
import ProjectsView from '@/components/Projects/ProjectsView.vue';
import Alert from '@/components/Alerts/alert.vue';

@Component({
  components: {
    ProjectsView,
    Alert,
  },
})
export default class ProjectsPage extends Vue {
  @Ref('alert') readonly alert!: Alert;

  projects = [];
  editProjectData = {
    id: 0,
    name: '',
    description: '',
  };
  modalAction = 'add';

  async mounted() {
    await this.getAllProjects();
  }

  async getAllProjects() {
    this.projects = (await ProjectService.getProjectList()).data;
  }

  fillProjectForm(projectData, action = 'add') {
    if (action === 'add') {
      this.editProjectData = {
        id: 0,
        name: '',
        description: '',
      };
    } else {
      this.editProjectData = { ...projectData };
    }
    this.modalAction = action;
    this.$bvModal.show('modal-project-edit');
  }

  async editProject(action: string, formData) {
    try {
      //todo remove load ALL project, edit current project list
      const answer = await ProjectService.edit(action, formData);
      (this.$refs.alert as Alert).success(answer.statusMessage);
      await this.getAllProjects();
    } catch (e: ErrorWrapper) {
      (this.$refs.alert as Alert).error(e.response.data.message);
    }
  }

  async deleteProjects(ids: number[]) {
    try {
      const answer = await ProjectService.delete(ids);
      if (answer.data.errors) {
        const errorMessages = answer.data.errors.map((err) => `Project with id ${err.id} not deleted. ${err.message}`);
        (this.$refs.alert as Alert).error(errorMessages.join('\n'));
      } else {
        (this.$refs.alert as Alert).success('Proect(s) deleted!');
      }
      await this.getAllProjects();
    } catch (e: ErrorWrapper) {
      console.error(e?.response?.data?.message);
    }
  }
}
