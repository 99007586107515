import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CurrencyFormat extends Vue {
  formatCurrency(sum: number, currency?: string): string {
    const options = !currency
      ? {}
      : {
          style: 'currency',
          currency: currency,
        };
    const uaFormat = new Intl.NumberFormat('uk-UA', options);
    return uaFormat.format(sum);
  }
}
