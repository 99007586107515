import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Formatter extends Vue {
  formatErrorMessage(error) {
    if (typeof error === 'string') return error;
    return 'message' in (error as Error) ? (error as Error).message : (error as string);
  }

  prettyJson(val: object | string, indent = 2) {
    if (typeof val !== 'object') {
      try {
        val = JSON.parse(val);
      } catch (err) {
        // console.warn('value is not JSON');
        return val;
      }
    }
    return JSON.stringify(val, null, indent);
  }
}
