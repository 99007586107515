<template>
  <div>
    <b-form class="m-auto" @submit.prevent="">
      <alert ref="alert" />
      <h1>Register</h1>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">User</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">Company</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">Project</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <b-form-group id="register-group-email" label="Email:" label-for="register-email">
              <b-form-input
                id="register-email"
                v-model="form.email"
                type="email"
                required
                placeholder="Email"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="register-group-password" label="Password:" label-for="register-password">
              <b-form-input
                id="register-password"
                v-model="form.password"
                type="password"
                required
                placeholder="Password"
              ></b-form-input>
            </b-form-group>
            <v-btn color="primary" @click="validateUser">Continue</v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <b-form-group id="register-group-company" label="Company name:" label-for="register-company">
              <b-form-input
                id="register-company"
                v-model="form.company"
                type="text"
                required
                placeholder="Company name"
              ></b-form-input>
            </b-form-group>

            <v-btn class="mr-10 red lighten-3" @click="e1 = 1">Back</v-btn>
            <v-btn color="primary" @click="validateCompany">Continue</v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <b-form-group id="register-group-project" label="Project name:" label-for="register-project">
              <b-form-input
                id="register-project"
                v-model="form.project"
                type="text"
                required
                placeholder="Project name"
              ></b-form-input>
            </b-form-group>

            <v-btn class="mr-10 red lighten-3" @click="e1 = 2">Back</v-btn>
            <v-btn
              id="register"
              color="primary"
              type="submit"
              variant="primary"
              name="register"
              @click="handleRegisterClick()"
            >
              Register
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </b-form>
  </div>
</template>

<script>
import { AuthService } from '@/services/auth.service';

export default {
  name: 'RegisterForm',
  data() {
    return {
      e1: 1,
      form: {
        username: '',
        password: '',
        company: '',
        project: '',
      },
      alert: {
        message: '',
        status: false,
      },
    };
  },
  methods: {
    async handleRegisterClick() {
      if (!(await this.validateProject())) return;
      try {
        await AuthService.registerFull(this.form);
        await AuthService.makeLogin({ email: this.form.email, password: this.form.password });
        this.$router.push({ path: 'admin/projects' }).catch((e) => {
          console.log('Err in register push', e);
        });
      } catch (error) {
        this.$refs.alert.error(error.message);
      }
    },

    async validateUser() {
      const { email, password } = this.form;
      try {
        if (email.length === 0) {
          throw new TypeError('Email empty');
        }
        if (password.length === 0) {
          throw new TypeError('Password empty');
        }
        const answer = await AuthService.validateRegUser(email);
        if (!answer.data.isValid) {
          throw new TypeError('User exist');
        }
        this.e1 = 2;
      } catch (error) {
        this.$refs.alert.error(error.message);
      }
    },

    async validateCompany() {
      try {
        if (this.form.company.length === 0) {
          throw new TypeError('Company name empty');
        }
        const answer = await AuthService.validateRegCompany(this.form.company);
        if (!answer.data.isValid) {
          throw new TypeError('Company exist');
        }
        this.e1 = 3;
      } catch (error) {
        this.$refs.alert.error(error.message);
      }
    },

    async validateProject() {
      try {
        if (this.form.project.length === 0) {
          throw new TypeError('Project name empty');
        }
      } catch (error) {
        this.$refs.alert.error(error.message);
        return false;
      }
      return true;
    },
  },
};
</script>
