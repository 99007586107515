import { Module } from 'vuex';
import { mutations } from './mutations';
import { RootState } from '@/store/types';
import { IToastState } from '../../interfaces';
import { actions } from './actions';

export const state: IToastState = {
  toastsList: [],
};

const namespaced = true;

export const toastState: Module<IToastState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
};
