import { Module } from 'vuex';

import { mutations } from './mutations';
import { RootState } from '@/store/types';
import { IAuthState } from '@/store/interfaces';

export const state: IAuthState = {
  accessTokenExpDate: '',
};

const namespaced = true;

export const authState: Module<IAuthState, RootState> = {
  namespaced,
  state,
  mutations,
};
