import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '@/store/types';
import { ICardListState } from '@/store/interfaces';

export const state: ICardListState = {
  cardList: [],
};

const namespaced = true;

export const cardList: Module<ICardListState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
