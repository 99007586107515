
import { Component, Vue, PropSync } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import ModalList from './ModalList.vue';
import ModalEditPay from './ModalEditPay.vue';
import { TSelectStruct } from '@/dto/base.dto';
import { PaysTableDto, TNewPay, TPay, newPay, TCurrencyBalance, TTransfer } from '@/dto/Pays.dto';
import { PaysService } from '@/services/pay.service';
import { CurrencyCode } from '@/dto/enums';
import TableCustom from '@/components/Tables/Table.vue';
import BalancePanel from '@/components/Balance/BalancePanel.vue';
import ModalTransferPay from './ModalTransferPay.vue';
import tableOptions from './table.options';
import { TDividePay } from '../../dto/Pays.dto';
import ToolTip from './Tooltip.vue';

@Component({
  components: {
    Treeselect,
    ModalList,
    ModalEditPay,
    ModalTransferPay,
    TableCustom,
    BalancePanel,
    ToolTip,
  },
})
export default class PaysTable extends Vue {
  @PropSync('pays', { type: Array, default: [] }) filteredPays!: PaysTableDto[];

  @State('banksList', { namespace: 'enumState' }) banksList!: Record<string, string>;
  @State('currencyList', { namespace: 'enumState' }) currencyList!: Record<number, string>;
  @State('periodicList', { namespace: 'enumState' }) periodicList!: string[];

  @State('articleList', { namespace: 'articleList' }) articleList!: TSelectStruct[];
  @State('cardList', { namespace: 'cardList' }) cardList!: TSelectStruct[];
  @State('contragentList', { namespace: 'contragentList' }) contragentList!: TSelectStruct[];
  @State('projectList', { namespace: 'projectList' }) projectList!: TSelectStruct[];

  @Action('setSelectedBalance', { namespace: 'balanceState' }) setSelectedBalance;
  @Action('setDefaultBalance', { namespace: 'balanceState' }) setDefaultBalance;

  tableName = 'PaysTable';
  setButton = {
    color: 'green lighten-5',
    text: 'set',
    textColor: '#4CAF50',
  };

  preventMainEdit = false;
  mainSectionHeight = 0;

  selectedRows: number[] = [];
  selectedRow: TPay | TNewPay = {} as TPay;

  modalListTree: TSelectStruct[] = [];
  editField = '';
  isMayTransfer = true;
  isMayUnTransfer = false;
  currentChoice = '';
  payId = null;
  asInSource = {};
  editAction = 'create';

  transferIn = {};
  transferOut = {};
  //Table options
  columns = [
    {
      field: 'dateTimePay',
      label: 'Date',
      sortable: true,
      firstSortType: 'desc',
      tdClass: 'text-center',
      width: '10%',
      formatFn: (val: string) => this.localeDate(val),
    },
    { field: 'sumOplata', label: 'Sum', sortable: true, width: '10%', sortFn: this.sortSum },
    {
      field: 'article.name',
      label: 'Article',
      sortable: true,
      width: '10%',
    },
    { field: 'project.name', label: 'Project', sortable: true, width: '10%' },
    { field: 'contragent.name', label: 'Contragent', sortable: true, width: '15%' },
    { field: 'card.name', label: 'Card', sortable: true, width: '25%' },
    { field: 'description', label: 'Description', sortable: false, width: '15%' },
    { field: 'customDescription', label: 'Add. info', sortable: false, width: '150px' },
  ];
  paginationOptions = tableOptions.paginationOptions;
  selectOptions = tableOptions.selectOptions;
  sortOptions = tableOptions.sortOptions(this.$store, this.tableName);

  async mounted() {
    console.log(this.filteredPays);
    this.$nextTick(() => {
      this.resizeTable();
      window.addEventListener('resize', this.resizeTable);
    });
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeTable);
  }

  elementGetHeight(className: string): number {
    const element = document.querySelector(className);
    if (!element) return 0;
    return element.clientHeight;
  }

  resizeTable() {
    const wrapHeight = this.elementGetHeight('.admin-view-page');
    const actionPanelHeight = this.elementGetHeight('.table-actions-panel');
    const wrapFooterHeight = this.elementGetHeight('.vgt-wrap__footer');
    const headerHeight = this.elementGetHeight('#vgt-table thead');
    this.mainSectionHeight = wrapHeight - actionPanelHeight - wrapFooterHeight - headerHeight - 5;
  }

  // Sort Functions
  sortSum(x: number, y: number) {
    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
  }

  // Table Functions
  onSetButtonClick(row, field) {
    let isList = false;
    this.preventMainEdit = true;
    switch (field) {
      case 'contragent.name':
        this.modalListTree = this.contragentList;
        this.editField = 'contragent';
        isList = true;
        break;
      case 'project.name':
        this.modalListTree = this.projectList;
        this.editField = 'project';
        isList = true;
        break;
      case 'article.name':
        this.modalListTree = this.articleList;
        this.editField = 'article';
        isList = true;
        break;
    }
    if (isList) {
      this.payId = row.id;
      this.currentChoice = row[this.editField]?.name ?? null;
      this.$bvModal.show('modal-list-edit');
    }
  }

  onCellClick(params) {
    if (this.preventMainEdit) {
      this.preventMainEdit = false;
      return;
    }
    this.payId = params.row.id;
    this.editPayForm('edit', params.row);
  }

  // Format Functions
  formatSumField(row: PaysTableDto, currency?: number) {
    try {
      const locale = this.$store.getters.locale || 'uk';
      const curr = row.currency ?? currency ?? '';
      return row.sumOplata.toLocaleString(locale, { style: 'currency', currency: curr });
    } catch {
      return 'error';
    }
  }

  formatName(row: PaysTableDto, field: string) {
    if (typeof row[field] === 'number') {
      const foundInTree = this.searchTree(this[`${field}List`], row[field]);
      return foundInTree?.name;
    }
    if (typeof row[field] === 'undefined') {
      return '<< empty >>';
    }
    const fieldName = 'formatName' + field[0].toUpperCase() + field.substring(1);

    if (row[fieldName]?.parent) return row[fieldName].names[0];
    if (row[fieldName]?.names?.length === 0) {
      return row[field]?.name || '<< empty >>';
    }
    return `${row[fieldName]?.names?.length} ${field}(s)`;
  }

  formBalanceOfSelected(selectedRows: TPay[]): TCurrencyBalance {
    const balance: TCurrencyBalance = {};

    for (const row of selectedRows) {
      const currency = row.currency;
      const sum = parseFloat(row.sumOplata as unknown as string);
      if (balance[currency] === undefined) {
        balance[currency] = { positive: 0, negative: 0 };
      }
      if (sum < 0) {
        balance[currency].negative += sum;
      } else {
        balance[currency].positive += sum;
      }
    }
    return balance;
  }

  selectionChanged() {
    this.selectedRows = (this.$refs['pays-table'] as Vue & { selectedRows }).selectedRows.map((row) => row.id) || [];
    this.isMayTransfer = false;
    this.isMayUnTransfer = false;
    const selected = this.selectedRows;
    switch (selected.length) {
      case 2: {
        const firstTransfer = this.getTransferPare(selected[0]);
        const secondTransfer = this.getTransferPare(selected[1]);
        if (firstTransfer !== undefined && secondTransfer !== undefined) {
          this.isMayTransfer = PaysService.checkMayTransfer(firstTransfer, secondTransfer);
        }
        if (secondTransfer !== undefined && firstTransfer?.transferId == secondTransfer.id) {
          this.isMayUnTransfer = true;
        }
        break;
      }
      case 1: {
        const row = this.filteredPays.find((row) => row.id === selected[0]);
        this.isMayUnTransfer = !!row?.transferId;
        this.isMayTransfer = !this.isMayUnTransfer;
        break;
      }
      case 0:
        this.isMayTransfer = true;
        break;
    }

    if (this.selectedRows.length > 0) {
      const selected = (this.$refs['pays-table'] as Vue & { selectedRows }).selectedRows;
      const balance = this.formBalanceOfSelected(selected);
      this.setSelectedBalance(balance);
    } else {
      this.setDefaultBalance();
    }
    this.$nextTick(() => {
      this.resizeTable();
    });
  }

  findByRow(id: number): TPay {
    return this.filteredPays.find((row) => row.id === id) as unknown as TPay;
  }

  findBank(bankId: string): string | undefined {
    const bankList = this.banksList;
    return Object.keys(bankList).find((bankKey) => bankList[bankKey] === bankId);
  }

  agregateRowsForMultipleEditForm(ids: number[]): { asInSource: string[]; pay: TNewPay } {
    const asInSource: string[] = ['id'];
    const firstRow = this.findByRow(ids[0]);
    const bank = this.findBank(firstRow.bank);
    const agregateRow = newPay({ ...firstRow, bank, currency: CurrencyCode[firstRow.currency] });

    for (const id of ids) {
      const rowById = this.findByRow(id);
      if (agregateRow.id == rowById.id) continue;
      const rowBank = this.findBank(rowById.bank);
      const row = newPay({ ...rowById, currency: CurrencyCode[rowById.currency], bank: rowBank });
      for (const key of Object.keys(row)) {
        if (asInSource.indexOf(key) > -1) continue;
        if (JSON.stringify(agregateRow[key]) !== JSON.stringify(row[key])) {
          asInSource.push(key);
        }
      }
    }
    return { asInSource, pay: agregateRow };
  }

  //Fill Form Pay
  editPayForm(action: string, row: TPay = {} as TPay) {
    if (row && row.id && row.transferId) {
      this.selectedRows = [row.id];
      this.$bvModal.show('modal-transfer-pay');
    } else {
      switch (action) {
        case 'add':
          this.selectedRow = newPay();
          this.editAction = 'add';
          break;
        case 'edit': {
          const bank = this.findBank(row.bank);
          const divided: TDividePay[] = [];
          if (row.divided !== undefined && row.divided?.length > 0) {
            for (const child of row.divided) {
              divided.push({ ...child, sumOplata: Math.abs(+child?.sumOplata ?? 0) });
            }
          }

          this.selectedRow = newPay({
            ...row,
            bank,
            currency: CurrencyCode[row.currency],
            divided,
          });
          this.editAction = 'edit';
          break;
        }
        case 'multiple-edit': {
          if (this.selectedRows.length > 1) {
            const agregation = this.agregateRowsForMultipleEditForm(this.selectedRows);
            this.asInSource = agregation.asInSource;
            this.selectedRow = agregation.pay;
            this.editAction = 'multiple-edit';
          } else if (this.selectedRows.length === 1) {
            row = this.findByRow(this.selectedRows[0]);
            this.editPayForm('edit', row);
            return;
          } else return;
          break;
        }
        default:
          return;
      }
      this.$bvModal.show('modal-edit-pay');
    }
  }

  //Edit actions
  async updatePayField(field: string, fieldId: number) {
    if (this.payId) {
      const updateAnswer = await PaysService.updatePayField([{ id: this.payId, field, value: fieldId }]);
      const id = this.filteredPays.findIndex((elem) => elem.id === this.payId);
      //TODO костыль хреновый.надо переделать расчет имен детских проектов и статей
      if (field == 'article' || field == 'project') {
        const fieldName = 'formatName' + field[0].toUpperCase() + field.substring(1);
        this.filteredPays[id][fieldName] = updateAnswer.data.success[0][fieldName];
      }
      this.filteredPays[id][field] = updateAnswer.data.success[0][field];
    }
  }

  async updateMultiEdit(row: TPay, changedFields: string[]) {
    for (const id of this.selectedRows) {
      const findedRow = this.findByRow(id);
      const editedRow = newPay({
        ...findedRow,
        currency: CurrencyCode[findedRow.currency],
      });
      for (const key of changedFields) {
        editedRow[key] = row[key];
      }
      const pay = (await PaysService.updatePay(editedRow)).data.success;
      this.tableUpdatePay(pay);
    }
  }

  tableUpdatePay(pay: PaysTableDto) {
    const id = this.filteredPays.findIndex((elem) => elem.id === pay.id);
    for (const key of Object.keys(pay)) {
      if (key == 'id') continue;
      this.filteredPays[id][key] = pay[key];
    }
  }

  //TODO need error messge show
  async addPay(row: TPay, action: string, changedFields?: string[]) {
    let message = 'Are you sure you want to update:';
    let pay: PaysTableDto;

    switch (action) {
      case 'add':
        pay = (await PaysService.createPay(row)).data.success;
        this.filteredPays.push(pay);
        break;
      case 'edit':
        pay = (await PaysService.updatePay(row)).data.success;
        this.tableUpdatePay(pay);
        break;
      case 'multiple-edit':
        if (!changedFields || changedFields?.length === 0) break;

        for (const field of changedFields) {
          message += ' ' + field;
        }

        this.$confirm({
          message: message,
          button: {
            no: 'No',
            yes: 'Yes',
          },
          callback: async (confirm: boolean) => {
            if (confirm) {
              await this.updateMultiEdit(row, changedFields);
            }
          },
        });
        break;
      default:
        return;
    }
  }

  async deletePays(ids: number[], cb?: () => void) {
    this.$confirm({
      message: 'Are you sure delete this pay(s) and its childrens?',
      button: {
        no: 'No',
        yes: 'Yes',
      },
      callback: async (confirm: boolean) => {
        if (confirm) {
          const response = await PaysService.delete(ids);
          if (response.status === 200) {
            for (const id of ids) {
              const index = this.filteredPays.findIndex((elem) => elem.id === id);
              if (index > -1) {
                this.filteredPays.splice(index, 1);
              }
            }
          }
          if (cb) cb();
        }
      },
    });
  }

  async approvePays(ids: number[]) {
    //todo need add confirmation
    await PaysService.approve(ids);

    this.$emit('refreshPays');
  }

  fillTransferForm() {
    this.$bvModal.show('modal-transfer-pay');
  }

  async updateTransfer(isCreateTransfer: boolean) {
    await PaysService.updateTransfer(this.selectedRows, isCreateTransfer);
    this.selectedRows = [];
    this.$emit('refreshPays');
  }

  async createTransfer(transfers: { transferIn: TTransfer; transferOut: TTransfer }) {
    await PaysService.createTransfer(transfers);
    this.$emit('refreshPays');
  }

  getTransferPare(id: number) {
    return this.filteredPays.find((row) => row.id === id);
  }

  onSortChange(sort: unknown) {
    this.$store.commit('sortState/SET_TABLE_SORT', {
      tableName: 'PaysTable',
      sort,
    });
  }
}
