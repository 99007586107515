import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class LogService extends BaseService {
  static async getLogs() {
    try {
      const response = await this.request({ auth: true }).get('system-log/system-log');

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async clearLogs() {
    try {
      const response = await this.request({ auth: true }).get('system-log/clear-sys-log');

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
