
import Vue from 'vue';
import { AuthService } from '@/services/auth.service';

export default Vue.extend({
  name: 'App',
  methods: {
    logout() {
      AuthService.makeLogout();
    },
    isLoggedIn() {
      return AuthService.isLoggedIn();
    },
    toggleDrawer() {
      this.$store.commit('navigationState/TOGGLE_DRAWER');
    },
  },
});
