/**
 * import and init global mixins
 */

import Vue from 'vue';

import localeFormat from './localeFormat';
import formatter from './formatter';
import mapEnums from './mapEnums';
import normalizeTree from './normalizeTree';
import currencyFormat from './currencyFormat';

Vue.mixin(localeFormat);
Vue.mixin(formatter);
Vue.mixin(mapEnums);
Vue.mixin(normalizeTree);
Vue.mixin(currencyFormat);
