import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { RootState } from '@/store/types';
import { ILocaleState } from '../../interfaces';

export const state: ILocaleState = {
  locale: 'uk',
};

const namespaced = true;

export const localeState: Module<ILocaleState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
};
