import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { IFilterState } from '../../interfaces';

export const actions: ActionTree<IFilterState, RootState> = {
  updateFilter({ commit }, options) {
    commit('SET_FILTER', options);
  },
  updateAllFilters({ commit }, filters) {
    commit('SET_ALL_FILTERS', filters);
  },
  clearFilters({ commit }) {
    commit('CLEAR_FILTERS');
  },
};
