<template>
  <div class="container">
    <alert ref="alert" />
    <b-form class="m-auto" @submit.prevent="">
      <h1>Login</h1>
      <b-form-group id="input-group-email" label="Email:" label-for="input-email">
        <b-form-input id="input-email" v-model="form.email" type="email" required placeholder="Email"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-password" label="Password:" label-for="input-password">
        <b-form-input
          id="input-password"
          v-model="form.password"
          type="password"
          required
          placeholder="Password"
        ></b-form-input>
      </b-form-group>

      <b-button id="login" type="submit" variant="primary" name="login" @click="handleClick($event)">Login</b-button>
    </b-form>
  </div>
</template>

<script>
import { AuthService } from '@/services/auth.service';

export default {
  name: 'LoginForm',
  data: () => ({
    form: {
      username: '',
      password: '',
    },
  }),
  methods: {
    async handleClick() {
      const { password, email } = this.form;
      try {
        this.loading = true;
        await AuthService.makeLogin({ email: email, password: password });
        this.$router.push({ path: '/admin/pays' }).catch((e) => {
          console.log('Err in login push', e);
        });
      } catch (error) {
        this.loading = false;
        this.$refs.alert.error(error.message);
      }
    },
  },
};
</script>
