import { MutationTree } from 'vuex';
import { IUserState } from '../../interfaces';

export const mutations: MutationTree<IUserState> = {
  SET_CURRENT_USER_ROLE(state, currentUserRole) {
    state.currentUserRole = currentUserRole;
  },
  SET_CURRENT_COMPANY(state, currentCompany) {
    state.currentCompany = currentCompany;
  },
  SET_CURRENT_COMPANY_ID(state, currentCompanyId) {
    state.currentCompanyId = currentCompanyId;
  },
};
