import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { IBalanceState } from '@/store/interfaces';

export const getters: GetterTree<IBalanceState, RootState> = {
  getBalance: (state) => {
    return state.balanceOfSelected ? state.balanceOfSelected : state.balance;
  },
  getBalanceNoReload: (state) => {
    return state.balance;
  },
};
