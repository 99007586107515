import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { INavigationState } from '../../interfaces';

export const actions: ActionTree<INavigationState, RootState> = {
  setMini({ commit }, newState: boolean) {
    commit('SET_MINI_STATE', newState);
  },

  setDrawer({ commit }, drawer: boolean) {
    commit('SET_DRAWER_STATE', drawer);
  },

  setPanel({ commit }, panel: string) {
    commit('SET_PANEL_STATE', panel);
  },
};
