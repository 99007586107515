import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { RootState } from '@/store/types';
import { IEnumListState } from '../../interfaces';
import { actions } from './actions';

export const state: IEnumListState = {
  banksList: {},
  currencyList: [],
  periodicList: [],
};

const namespaced = true;

export const enumState: Module<IEnumListState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
