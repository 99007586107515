import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { IProjectListState } from '@/store/interfaces';
import { ProjectDto } from '@/dto/Projects.dto';

export const getters: GetterTree<IProjectListState, RootState> = {
  getProjectList(state): ProjectDto[] {
    return state.projectList || [];
  },
};
