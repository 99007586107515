import { Module } from 'vuex';
import { mutations } from './mutations';
import { RootState } from '@/store/types';
import { IDomState } from '../../interfaces';
import { getters } from './getters';

export const state: IDomState = {
  windowWidth: 0,
  widthExtraSmall: 320,
  widthSmall: 640,
  widthTablet: 1024,
};

const namespaced = true;

export const domState: Module<IDomState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
};
