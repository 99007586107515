import { MutationTree } from 'vuex';
import { ISortState } from '@/store/interfaces';

export const mutations: MutationTree<ISortState> = {
  SET_TABLE_SORT(state, { tableName, sort }) {
    state.table[tableName] = sort;
    localStorage.setItem('tableSort', JSON.stringify(state.table));
  },
  SET_ALL_SORTS(state, sorts) {
    state.table = sorts;
  },
};
